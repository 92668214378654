import {toast} from 'react-toastify'
import {useContext, useState, useEffect} from 'react'
import {toAbsoluteUrl, compareArrays} from 'helpers'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
//STYLES
import styles from './Interests.module.scss'

// CONTEXT
import SettingsContext, {Interest} from 'context/SettingsContext'
import SingleInterest from './components/SingleInterest/SingleInterest'
import { useLocation, useNavigate } from 'react-router-dom'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'

const Interests = () => {
  const {t} = useTranslation()
  const {interests, updateInterests, checkInterestsDiff, checkInterestsSave, interestList} =
    useContext(SettingsContext)
  const [newInterests, setNewInterests] = useState<number[]>([])
  const navigate = useNavigate()
  const location = useLocation()
  const routePath = location.pathname.split('/')[1]

  useEffect(() => {
    if (interests) {
      setNewInterests(interests)
    }
  }, [interests])

  const addInterest = (interest: number) => {
    const tmp = [...newInterests, interest]
    setNewInterests(tmp)
  }

  const removeInterest = (interest: number) => {
    const tmp = newInterests.filter((item) => item !== interest)
    setNewInterests(tmp)
  }

  return (
    <div className={styles.interests}>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.interests')}</div>
        <div className={styles.desc}>{t('SettingsPage.selectInterestPrompt')}</div>
      </div>
      <div className={styles.content}>
        {interestList.map((interest: Interest, index: number) => (
          <SingleInterest
            interest={interest}
            key={index}
            newData={newInterests}
            removeInterest={removeInterest}
            addInterest={addInterest}
          />
        ))}
      </div>
      {
        routePath === 'signup' ? (
            <button
              onClick={async () => {
                if (!checkInterestsSave(newInterests)) return
                if (checkInterestsDiff(newInterests)) {
                  const success = await updateInterests(newInterests)
                  if (success) {
                    navigate(`/${routePath}/${EnumSettingsTab.OverlaySetup}`)
                  }
                } else {
                  navigate(`/${routePath}/${EnumSettingsTab.OverlaySetup}`)
                }
              }}
              className={classNames(styles.save_btn, {
                [styles.disabled]: !checkInterestsSave(newInterests)
              })}
            >
              {t('Buttons.next')}
            </button>
        ): (
          <>
            {checkInterestsDiff(newInterests) && checkInterestsSave(newInterests) ? (
              <button
                onClick={() => {
                  updateInterests(newInterests)
                }}
                className={styles.save_btn}
              >
                {t('Buttons.save')}
              </button>
            ) : null}
          </>
        )
      }
    </div>
  )
}

export default Interests
