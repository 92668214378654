import {useState, useContext} from 'react'
import {toAbsoluteUrl} from 'helpers'
// STLYES
import styles from './LanguagePicker.module.scss'
// COMPONENTS
import LanguagesDropdown from './LanguagesDropdown/LanguagesDropdown'
import ClickAwayListener from 'react-click-away-listener'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContextFirebase'

const LanguagePicker = () => {
  const {user} = useContext(AppLevelContext)
  const [languagePicker, setLanguagePicker] = useState(false)
  return (
    <ClickAwayListener onClickAway={() => setLanguagePicker(false)}>
      <div>
        <div className={styles.languagePicker} onClick={() => setLanguagePicker(!languagePicker)}>
          <img
            src={toAbsoluteUrl(
              `/assets/icons/Flags/${
                user?.language_preference === 'tr'
                  ? 'tr.png'
                  : user?.language_preference === 'en'
                  ? 'eng.jpeg'
                  : user?.language_preference === 'es'
                  ? 'es.png'
                  : user?.language_preference === 'fr'
                  ? 'fr.png'
                  : user?.language_preference === 'de'
                  ? 'de.png'
                  : user?.language_preference === 'ar'
                  ? 'ar.png'
                  : user?.language_preference === 'ru'
                  ? 'ru.png'
                  : user?.language_preference === 'cs'
                  ? 'cs.png'
                  : user?.language_preference === 'nl'
                  ? 'nl.png'
                  : user?.language_preference === 'sk'
                  ? 'sk.png'
                  : user?.language_preference === 'pt'
                  ? 'pt.png'
                  : 'en.png'
              }`
            )}
            alt='flag'
          />
        </div>
        {languagePicker && <LanguagesDropdown />}
      </div>
    </ClickAwayListener>
  )
}

export default LanguagePicker
