import {createContext, useContext, useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import axios from 'axios'
import IBAN from 'iban'
// Context
import AppLevelContext from './AppLevelContext'
import {createPayoutService} from 'services/payoutService'
import PayoutAccountCreateDto from 'services/model/dto/payouts/payoutAccountCreateDto'
import GetTaxDocumentsResponse from 'services/model/response/payouts/getTaxDocumentsResponse'
import EnumStreamerTaxDocumentType from 'services/model/enum/enumStreamerTaxDocumentType'
import { createIndexService } from 'services/indexService'

export interface Country {
  label: string
  value: string
}
export interface City {
  label: string
  value: number
}

export interface District {
  id: number
  name: string
  city_id: number
}

export interface TaxRates {
  withholding_rate: number
  VAT_rate: number
  VAT_withholding_rate: number
}

interface PayoutAccountContextInterface {
  countries: Country[]
  getCountries: () => void
  payoutAccountData: PayoutAccountCreateDto | null
  setPayoutAccountData: (data: PayoutAccountCreateDto | null) => void
  getPayoutAccountData: () => void
  sendPayoutAccountData: (data: PayoutAccountCreateDto) => void
  checkPayoutAccountData: (data: PayoutAccountCreateDto) => {
    isValid: boolean
    message: string
    body?: any
  }
  checkPayoutAccountDiff: () => boolean
  cities: City[]
  districts: District[]
  getAllDistricts: () => void
  getTaxDocuments: () => void
  taxRates: TaxRates | null
  taxDocuments: GetTaxDocumentsResponse | null
  uploadTaxDocument: (
    type: EnumStreamerTaxDocumentType,
    file: File,
    progressHandler: (progress: number | null) => void
  ) => Promise<void>
}

export const PayoutAccountContext = createContext<PayoutAccountContextInterface>({
  countries: [],
  getCountries: () => {},
  payoutAccountData: null,
  setPayoutAccountData: (data: PayoutAccountCreateDto | null) => {},
  getPayoutAccountData: () => {},
  sendPayoutAccountData: (data: PayoutAccountCreateDto) => {},
  checkPayoutAccountData: (data: PayoutAccountCreateDto) => {
    return {isValid: false, message: '', body: {}}
  },
  checkPayoutAccountDiff: () => {
    return false
  },
  cities: [],
  districts: [],
  getAllDistricts: () => {},
  getTaxDocuments: () => {},
  taxRates: null,
  taxDocuments: null,
  uploadTaxDocument: (
    type: EnumStreamerTaxDocumentType,
    file: File,
    progressHandler: (progress: number | null) => void
  ) => {
    return Promise.resolve()
  },
})

interface PayoutAccountContextType {
  children: React.ReactNode
}

export const PayoutAccountContextProvider = ({children}: PayoutAccountContextType) => {
  const payoutService = createPayoutService()
  const indexService = createIndexService()
  const [initialPayoutAccountData, setInitialPayoutAccountData] =
    useState<PayoutAccountCreateDto | null>(null)
  const [payoutAccountData, setPayoutAccountData] = useState<PayoutAccountCreateDto | null>(null)
  const [countries, setCountries] = useState<Country[]>([])
  const [cities, setCities] = useState<City[]>([])
  const [districts, setDistricts] = useState<District[]>([])
  const [taxRates, setTaxRates] = useState<TaxRates | null>(null)
  const [taxDocuments, setTaxDocuments] = useState<GetTaxDocumentsResponse | null>(null)

  const uploadTaxDocument = async (
    type: EnumStreamerTaxDocumentType,
    file: File,
    progressHandler: (progress: number | null) => void
  ) => {
    try {
      if (type === EnumStreamerTaxDocumentType.TRExemption) {
        const response = await payoutService.uploadExemptionPdf(file, progressHandler)
        toast.success('Tax document uploaded successfully.')
        if (taxDocuments) {
          setTaxDocuments({
            ...taxDocuments,
            exemptionDocument: response,
          })
        } else {
          setTaxDocuments({
            exemptionDocument: response,
            petitionDocument: null,
          })
        }
      } else if (type === EnumStreamerTaxDocumentType.TRPetition) {
        const response = await payoutService.uploadPetitionPdf(file, progressHandler)
        toast.success('Tax document uploaded successfully.')
        if (taxDocuments) {
          setTaxDocuments({
            ...taxDocuments,
            petitionDocument: response,
          })
        } else {
          setTaxDocuments({
            exemptionDocument: null,
            petitionDocument: response,
          })
        }
      }
      progressHandler(null)
    } catch (error) {
      console.log(error)
    }
  }

  const getCountries = async () => {
    try {
      const response = await indexService.getCountries()
      setCountries(response.countries_array)
    } catch (error) {
      console.log(error)
    }
  }

  const getTaxDocuments = async () => {
    try {
      const response = await payoutService.getTaxDocuments()
      setTaxDocuments(response)
    } catch (error) {
      console.log(error)
    }
  }

  const getPayoutAccountData = async (): Promise<boolean> => {
    try {
      const response = await payoutService.getPayoutAccount()
      if (response.payoutAccount) {
        setPayoutAccountData(response.payoutAccount)
        setInitialPayoutAccountData(response.payoutAccount)
        setTaxRates({
          withholding_rate: response.payoutAccount.withholding_rate,
          VAT_rate: response.payoutAccount.VAT_rate,
          VAT_withholding_rate: response.payoutAccount.VAT_withholding_rate,
        })
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const checkPayoutAccountData = (
    data: PayoutAccountCreateDto
  ): {isValid: boolean; message: string; body?: any} => {
    let body: any = {}
    if (data.billing_country_id !== 'TR') {
      if (
        !data.billing_country_id ||
        !data.individual_full_name ||
        !data.individual_id_number ||
        !data.individual_address ||
        !data.wise_email ||
        (data.individual_full_name &&
          data.individual_full_name.split(' ').filter(Boolean).length <= 1)
      ) {
        return {isValid: false, message: 'Please fill all required fields.'}
      }
      body = {
        billing_country_id: data.billing_country_id,
        individual_full_name: data.individual_full_name,
        individual_id_number: data.individual_id_number,
        individual_address: data.individual_address,
        wise_email: data.wise_email,
        payment_method: 'wise',
      }
      // TR TAX PERSONAL
    } else if (
      data.billing_country_id === 'TR' &&
      data.billing_account_type === 'personal' &&
      data.billing_preference === 'available'
    ) {
      if (
        !data.payment_method ||
        !data.IBAN ||
        !data.individual_full_name ||
        !data.individual_id_number ||
        !data.individual_city_id ||
        !data.individual_district_id ||
        (data.individual_full_name &&
          data.individual_full_name.split(' ').filter(Boolean).length <= 1)
      ) {
        return {isValid: false, message: 'Please fill all required fields.'}
      }
      body = {
        billing_country_id: 'TR',
        billing_preference: 'available',
        billing_account_type: 'personal',
        individual_full_name: data.individual_full_name,
        individual_id_number: data.individual_id_number,
        individual_city_id: data.individual_city_id,
        individual_district_id: data.individual_district_id,
        payment_method: 'wire',
        IBAN: data.IBAN,
      }
      // TR TAX BUSINESS
    } else if (
      data.billing_country_id === 'TR' &&
      data.billing_account_type === 'business' &&
      data.billing_preference === 'available'
    ) {
      if (
        !data.payment_method ||
        !data.IBAN ||
        !data.business_full_name ||
        !data.business_city_id ||
        !data.business_district_id ||
        !data.business_full_address ||
        !data.business_tax_id ||
        !data.business_tax_administration
      ) {
        return {isValid: false, message: 'Please fill all required fields.'}
      }
      body = {
        billing_country_id: 'TR',
        billing_preference: 'available',
        billing_account_type: 'business',
        payment_method: 'wire',
        IBAN: data.IBAN,
        business_full_name: data.business_full_name,
        business_city_id: data.business_city_id,
        business_district_id: data.business_district_id,
        business_full_address: data.business_full_address,
        business_tax_id: data.business_tax_id,
        business_tax_administration: data.business_tax_administration,
      }
      // No Tax no Exemption
    } else if (
      data.billing_country_id === 'TR' &&
      data.billing_preference === 'not_available' &&
      data.tax_exemption_type === 'not_available'
    ) {
      if (
        !data.payment_method ||
        !data.IBAN ||
        !data.individual_full_name ||
        !data.individual_id_number ||
        !data.individual_city_id ||
        !data.individual_district_id ||
        (data.individual_full_name &&
          data.individual_full_name.split(' ').filter(Boolean).length <= 1)
      ) {
        return {
          isValid: false,
          message: 'Please fill all fields',
        }
      }
      body = {
        billing_country_id: 'TR',
        billing_preference: 'not_available',
        tax_exemption_type: 'not_available',
        payment_method: data.payment_method,
        IBAN: data.IBAN,
        individual_full_name: data.individual_full_name,
        individual_id_number: data.individual_id_number,
        individual_city_id: data.individual_city_id,
        individual_district_id: data.individual_district_id,
      }
    } else if (
      data.billing_country_id === 'TR' &&
      data.billing_preference === 'not_available' &&
      data.tax_exemption_type === 'available'
    ) {
      if (
        !data.payment_method ||
        !data.IBAN ||
        !data.individual_full_name ||
        !data.individual_id_number ||
        !data.individual_city_id ||
        !data.individual_district_id ||
        (data.individual_full_name &&
          data.individual_full_name.split(' ').filter(Boolean).length <= 1)
      ) {
        return {
          isValid: false,
          message: 'Please fill all fields',
        }
      }
      if (taxDocuments?.exemptionDocument === null || taxDocuments?.petitionDocument === null) {
        return {
          isValid: false,
          message: 'Please upload all required documents',
        }
      }
      body = {
        billing_country_id: 'TR',
        billing_preference: 'not_available',
        tax_exemption_type: 'available',
        payment_method: data.payment_method,
        IBAN: data.IBAN,
        individual_full_name: data.individual_full_name,
        individual_id_number: data.individual_id_number,
        individual_city_id: data.individual_city_id,
        individual_district_id: data.individual_district_id,
      }
    } else {
      return {
        isValid: false,
        message: 'Please fill all fields',
      }
    }

    // CHECK FOR NULL
    for (const [value] of Object.entries(body)) {
      if (value === '' || value === null) {
        return {
          isValid: false,
          message: 'Please fill all fields',
        }
      }
    }

    // ID VERIFICATION FOR TR
    if (body.individual_id_number && body.billing_country_id === 'TR') {
      const idNumber = body.individual_id_number
      if (idNumber.length !== 11) {
        return {
          isValid: false,
          message: 'Please enter valid ID number',
        }
      } else if (body.individual_id_number === '') {
        return {
          isValid: false,
          message: 'Please enter valid ID number',
        }
      } else {
        body.individual_id_number = idNumber
      }
    }

    // IBAN VALIDATION
    if (body.IBAN) {
      const fixedIBAN = body.IBAN.replace(/\s/g, '')
      if (!IBAN.isValid(fixedIBAN)) {
        return {
          isValid: false,
          message: 'Please enter valid IBAN',
        }
      }
      body.IBAN = fixedIBAN
    }

    return {
      isValid: true,
      message: 'Success',
      body: body,
    }
  }

  const sendPayoutAccountData = async (data: PayoutAccountCreateDto) => {
    const response = checkPayoutAccountData(data)
    if (!response.isValid) {
      toast.error(response.message)
      return
    }
    if (!response.body) {
      toast.error('Please fill all fields')
      return
    }
    const body = response.body

    try {

      await toast.promise(payoutService.updatePayoutAccount(body), {
        pending: 'Updating payout account...',
        success: "You've successfully updated your payout account",
      })
      setInitialPayoutAccountData(data)
      setPayoutAccountData(data)
    } catch (error) {
      toast.error("You've failed to update your payout account")
      console.log(error)
    }
  }

  // GET ALL CITIES
  const getAllCities = async () => {
    if (!payoutAccountData) {
      return
    }
    try {
      const response = await indexService.getCities(payoutAccountData.billing_country_id)
      setCities(response.cities_array)
    } catch (error) {
      console.log(error)
    }
  }

  const getAllDistricts = async () => {
    if (!payoutAccountData) {
      return
    }
    const determineCityID = () => {
      if (payoutAccountData.billing_account_type === 'business') {
        return payoutAccountData.business_city_id
      } else {
        return payoutAccountData.individual_city_id
      }
    }
    const cityID = determineCityID()
    if (!cityID) {
      return
    }
    try {
      const response = await indexService.getDistricts(cityID)
      setDistricts(response.districts)
    } catch (error) {
      console.log(error)
    }
  }

  const checkPayoutAccountDiff = (): boolean => {
    if (!initialPayoutAccountData || !payoutAccountData) {
      if (initialPayoutAccountData === payoutAccountData) {
        return false
      }
      return true
    }
    if (JSON.stringify(initialPayoutAccountData) === JSON.stringify(payoutAccountData)) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (payoutAccountData?.billing_country_id) {
      getAllCities()
    }
    // eslint-disable-next-line
  }, [payoutAccountData?.billing_country_id])

  useEffect(() => {
    if (payoutAccountData?.individual_city_id || payoutAccountData?.business_city_id) {
      getAllDistricts()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutAccountData?.business_city_id, payoutAccountData?.individual_city_id])

  useEffect(() => {
    if (payoutAccountData) {
      // console.log(checkPayoutAccountDiff(),checkPayoutAccountData(payoutAccountData), payoutAccountData, initialPayoutAccountData)
    }
  }, [payoutAccountData])

  return (
    <PayoutAccountContext.Provider
      value={{
        countries,
        getCountries,
        payoutAccountData,
        setPayoutAccountData,
        getPayoutAccountData,
        sendPayoutAccountData,
        checkPayoutAccountData,
        checkPayoutAccountDiff,
        cities,
        districts,
        getAllDistricts,
        taxRates,
        getTaxDocuments,
        taxDocuments,
        uploadTaxDocument,
      }}
    >
      {children}
    </PayoutAccountContext.Provider>
  )
}

export default PayoutAccountContext
