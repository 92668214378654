import {toAbsoluteUrl} from 'helpers'
import styles from './KickVerification.module.scss'

const KickVerification = () => {
  return (
    <div className={styles.kickVerification}>
      <section className={styles.titleSection}>
        <h1 className={styles.title}>Connect Kick channel</h1>
        <p className={styles.subtitle}>
          Welcome to <b>LIVAD</b>! Before you can get started you must link your <b>Kick</b>{' '}
          channel.
        </p>
      </section>
      <section className={styles.stepSection}>
        <div className={styles.title}>
          <img src={toAbsoluteUrl('/assets/icons/Platforms/kick-icon.png')} alt='Kick' />
          Link Kick account
        </div>
        <div className={styles.stepList}>
          <div className={styles.step}>
            1: Go to{' '}
            <a href='https://kick.com/livad' target='_blank'>
              https://kick.com/livad
            </a>
          </div>
          <div className={styles.step}>
            2: Open the chat and type <span>!verify 5095246013314</span>
          </div>
          <div className={styles.step}>
            3: Add <span>livad</span> as a moderator{' '}
            <a href='https://kick.com/dashboard/community/moderators' target='_blank'>
              here
            </a>
          </div>
        </div>
        <button className={styles.verifyBtn}>Verify</button>
      </section>
    </div>
  )
}

export default KickVerification
