import {useContext, useState, useEffect} from 'react'
import styles from './SocialAccounts.module.scss'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import {toast} from 'react-toastify'
import {compareObjects} from 'helpers'
import {useTranslation} from 'react-i18next'
// CONTEXT
import SettingsContext from 'context/SettingsContext'
import {SocialMediaDto} from 'services/model/dto/streamerAccountModels'
import { useLocation, useNavigate } from 'react-router-dom'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'

const SocialAccounts = () => {
  const {t} = useTranslation()
  const {socialMedia, updateSocialMedia, checkSocialMediaSave, checkSocialMediaDiff} =
    useContext(SettingsContext)
  const [newSocialMedia, setNewSocialMedia] = useState<SocialMediaDto>({})
  const navigate = useNavigate()
  const location = useLocation()
  const routePath = location.pathname.split('/')[1]

  useEffect(() => {
    if (socialMedia) {
      setNewSocialMedia({
        discord_username: socialMedia.discord_username,
        twitter_username: socialMedia.twitter_username,
        instagram_username: socialMedia.instagram_username,
        tiktok_username: socialMedia.tiktok_username,
      })
    }
  }, [socialMedia])

  return (
    <div className={styles.socialAccounts}>
      <div className={styles.line}>
        <div className={styles.label}>
          <img src={toAbsoluteUrl('/assets/icons/Platforms/discord-icon.png')} alt='' />
          Discord
        </div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.username') + '#0000'}
          value={newSocialMedia.discord_username}
          onChange={(e) => {
            setNewSocialMedia({...newSocialMedia, discord_username: e.target.value})
          }}
        />
      </div>
      <div className={styles.line}>
        <div className={styles.label}>
          <img src={toAbsoluteUrl('/assets/icons/Platforms/twitter-icon.png')} alt='' />
          Twitter
          <span className={styles.optional}>({t('SettingsPage.optional')})</span>
        </div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.username')}
          defaultValue={newSocialMedia.twitter_username}
          onChange={(e) => {
            setNewSocialMedia({...newSocialMedia, twitter_username: e.target.value})
          }}
        />
      </div>
      <div className={styles.line}>
        <div className={styles.label}>
          <img src={toAbsoluteUrl('/assets/icons/Platforms/instagram-icon.png')} alt='' />
          Instagram
          <span className={styles.optional}>({t('SettingsPage.optional')})</span>
        </div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.username')}
          defaultValue={newSocialMedia.instagram_username}
          onChange={(e) => {
            setNewSocialMedia({...newSocialMedia, instagram_username: e.target.value})
          }}
        />
      </div>
      <div className={styles.line}>
        <div className={styles.label}>
          <img src={toAbsoluteUrl('/assets/icons/Platforms/tiktok-icon.png')} alt='' />
          TikTok
          <span className={styles.optional}>({t('SettingsPage.optional')})</span>
        </div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.username')}
          defaultValue={newSocialMedia.tiktok_username}
          onChange={(e) => {
            setNewSocialMedia({...newSocialMedia, tiktok_username: e.target.value})
          }}
        />
      </div>
      {
        routePath === 'signup' ? (
          <div className={classNames(styles.line, styles.lineSave, {
            [styles.disabled]: !checkSocialMediaSave(newSocialMedia)
          })}>
                <button
                  onClick={async () => {
                    if (!checkSocialMediaSave(newSocialMedia)) return
                    if (checkSocialMediaDiff(newSocialMedia)) {
                      const success = await updateSocialMedia(newSocialMedia)
                      if (success) {
                        navigate(`/${routePath}/${EnumSettingsTab.StreamingPreferences}`)
                      }
                    } else {
                      navigate(`/${routePath}/${EnumSettingsTab.StreamingPreferences}`)
                    }
                  }}
                >
                  {t('Buttons.next')}
                </button>
            </div>
        ): (
          <>
            {checkSocialMediaDiff(newSocialMedia) && checkSocialMediaSave(newSocialMedia) ? (
              <div className={classNames(styles.line, styles.lineSave)}>
                <button
                  onClick={() => {
                    updateSocialMedia(newSocialMedia)
                  }}
                >
                  {t('Buttons.save')}
                </button>
              </div>
            ) : null}
          </>
        )
      }
    </div>
  )
}

export default SocialAccounts
