import styles from './Labels.module.scss'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'

const Labels = ({labels, setLabels}: any) => {
  const {t} = useTranslation()
  return (
    <div className={styles.labels_content}>
      <div className={styles.labels_title}>{t('MyClipsPopup.labels')}</div>

      {labels.map((label: any, index: number) => (
        <div
          className={styles.labelItem}
          key={index}
          onClick={() => {
            const newLabels = labels.map((l: any) => {
              if (l.id === label.id) {
                if (l.selected === false || l.selected === 0) {
                  l.selected = 1
                } else {
                  l.selected = 0
                }
              }
              return l
            })
            setLabels(newLabels)
          }}
        >
          <div
            className={classNames(styles.labelCheckbox, {
              [styles.checked]: label.selected === 1,
            })}
          >
            <img src={toAbsoluteUrl('/assets/icons/MyClips/checkIcon.png')} alt='checked' />
          </div>
          {label.name}
        </div>
      ))}
    </div>
  )
}

export default Labels
