import styles from '../../Select/Select.module.scss'

import {useState, useContext, useEffect} from 'react'
import {toAbsoluteUrl} from 'helpers'
import ClickAwayListener from 'react-click-away-listener'

// Context
import SettingsContext from 'context/SettingsContext'
import {Country} from 'context/PayoutAccountContext'

const CountrySelect = ({newProfileDetails, setNewProfileDetails}: any) => {
  const {countries, profileDetails} = useContext(SettingsContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCountries, setFilteredCountries] = useState<Country[]>(countries)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)

  const filterCountries = (searchTerm: string) => {
    if (searchTerm === '') return setFilteredCountries(countries)

    const filteredCountries = countries.filter((country) => {
      return country.label.toLowerCase().includes(searchTerm.toLowerCase())
    })
    setFilteredCountries(filteredCountries)
  }

  useEffect(() => {
    filterCountries(searchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    if (profileDetails?.country_id) {
      const selectedCountry = countries.find(
        (country) => country.value === profileDetails?.country_id
      )

      if (selectedCountry) {
        setSelectedCountry(selectedCountry)
      }
    }
  }, [])

  useEffect(() => {
    if (selectedCountry) {
      setNewProfileDetails({...newProfileDetails, country_id: selectedCountry.value})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])

  const handleCountryClick = (country: Country) => {
    setSelectedCountry(country)
    setSearchTerm('')
    setIsOpen(false)
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false)
        setSearchTerm('')
      }}
    >
      <div className={styles.selectBox}>
        <div className={styles.selectBox_selected} onClick={() => setIsOpen(!isOpen)}>
          {selectedCountry ? selectedCountry.label : 'Select Country'}
          <img
            className={styles.selectBox_arrow}
            src={toAbsoluteUrl('/assets/icons/Settings/arrowIcon.png')}
            alt='arrow'
          />
        </div>
        {isOpen && (
          <div className={styles.selectItem_list}>
            <div className={styles.listItem_search}>
              <input
                type='text'
                placeholder='Search'
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className={styles.listResults}>
              {filteredCountries.map((country, idx) => (
                <span
                  className={styles.listItem}
                  key={idx}
                  onClick={() => handleCountryClick(country)}
                >
                  {country.label}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default CountrySelect
