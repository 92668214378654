import {useContext, useState, useEffect} from 'react'
import {toAbsoluteUrl, compareObjects} from 'helpers'
import {toast} from 'react-toastify'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
// STYLES
import styles from './StreamingPreferences.module.scss'
// CONTEXT
import SettingsContext from 'context/SettingsContext'
import {StreamingPreferencesDto} from 'services/model/dto/streamerAccountModels'
import { useLocation, useNavigate } from 'react-router-dom'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'

const StreamingPreferences = () => {
  const {t} = useTranslation()
  const {
    streamingPreferences,
    streamingLanguages,
    checkStreamingPreferencesSave,
    checkStreamingPreferencesDiff,
    updateStreamingPreferences,
  } = useContext(SettingsContext)
  const [saveable, setSaveable] = useState(false)
  const [newStreamingPreferences, setNewStreamingPreferences] = useState<StreamingPreferencesDto>(
    {}
  )
  const navigate = useNavigate()
  const location = useLocation()
  const routePath = location.pathname.split('/')[1]

  useEffect(() => {
    if (streamingPreferences) {
      setNewStreamingPreferences({
        broadcasting_language_id: streamingPreferences.broadcasting_language_id,
        broadcasting_software: streamingPreferences.broadcasting_software,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamingPreferences]);
  console.log('aaaaaa',checkStreamingPreferencesDiff(newStreamingPreferences), checkStreamingPreferencesSave(newStreamingPreferences)); 

  return (
    <div className={styles.streamingPreferences}>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.broadcastingSoftware')}</div>
        <div className={styles.inLine}>
          <span>
            <img
              src={
                newStreamingPreferences.broadcasting_software === 'obs'
                  ? toAbsoluteUrl('/assets/icons/Settings/obs-icon.png')
                  : newStreamingPreferences.broadcasting_software === 'streamlabs'
                  ? toAbsoluteUrl('/assets/icons/Settings/streamlabs-icon.png')
                  : newStreamingPreferences.broadcasting_software === 'twitchstudio'
                  ? toAbsoluteUrl('/assets/icons/Platforms/twitch-icon.png')
                  : toAbsoluteUrl('/assets/icons/Settings/other-icon.png')
              }
              alt=''
            />
          </span>
          <select
            value={newStreamingPreferences.broadcasting_software ?? ''}
            onChange={(e) => {
              setNewStreamingPreferences({
                ...newStreamingPreferences,
                broadcasting_software: e.target.value,
              })
            }}
          >
            <option value='' hidden>
              {t('SettingsPage.selectBroadcastingSoftware')}
            </option>
            <option value='obs'>OBS</option>
            <option value='streamlabs'>Streamlabs</option>
            <option value='twitchstudio'>Twitch Studio</option>
            <option value='other'>Other</option>
          </select>
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.broadcastingLanguage')}</div>
        <div className={styles.inLine}>
          <span>
            <img
              alt=''
              src={
                newStreamingPreferences.broadcasting_language_id ?
                streamingLanguages.find(
                  (language) => language['id'] === newStreamingPreferences.broadcasting_language_id
                )?.['flag_url']: toAbsoluteUrl('/assets/icons/Settings/other-icon.png')
              }
            />
          </span>
          <select
            onChange={(e) => {
              setNewStreamingPreferences({
                ...newStreamingPreferences,
                broadcasting_language_id: e.target.value,
              })
            }}
            value={newStreamingPreferences.broadcasting_language_id ?? ''}
          >
            <option value='' hidden>
              {t('SettingsPage.selectBroadcastingLanguage')}
            </option>
            {streamingLanguages.map((language, index) => (
              <option key={index} value={language['id']}>
                {language['name']}
              </option>
            ))}
          </select>
        </div>
      </div>
      {
        routePath === 'signup' ? (
          <div className={classNames(styles.line, styles.lineSave)}>
                <button
                  onClick={async () => {
                    if (!checkStreamingPreferencesSave(newStreamingPreferences)) return
                    if (checkStreamingPreferencesDiff(newStreamingPreferences)) {
                      const success = await updateStreamingPreferences(newStreamingPreferences)
                      if (success) {
                        navigate(`/${routePath}/${EnumSettingsTab.SponsorshipPreferences}`)
                      }
                    } else {
                      navigate(`/${routePath}/${EnumSettingsTab.SponsorshipPreferences}`)
                    }
                  }}
                  className={classNames({
                    [styles.disabled]: !checkStreamingPreferencesSave(newStreamingPreferences)
                  })}
                >
                  {t('Buttons.next')}
                </button>
            </div>
        ): (
          <>
            {checkStreamingPreferencesDiff(newStreamingPreferences) && checkStreamingPreferencesSave(newStreamingPreferences) ? (
              <div className={classNames(styles.line, styles.lineSave)}>
                <button
                  onClick={() => {
                    updateStreamingPreferences(newStreamingPreferences)
                  }}
                >
                  {t('Buttons.save')}
                </button>
              </div>
            ) : null}
          </>
        )
      }
    </div>
  )
}

export default StreamingPreferences
