import {toAbsoluteUrl} from 'helpers'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import styles from './Earnings.module.scss'

// Context
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import ReferralsContext from 'context/ReferralsContext'
const Earnings = () => {
  const {user} = useContext(AppLevelContext)
  const {referralsList} = useContext(ReferralsContext)
  const {t} = useTranslation()
  return (
    <div className={styles.earnings}>
      <div className={styles.earningItem}>
        <div className={styles.earningItemTitle}>
          <div className={styles.tooltip}>
            <img src={'assets/icons/Payments/helpTooltip_icon.png'} alt='tooltip' />
            <span className={styles.tooltipText}>
              {t('RecruitedFriendPage.totalEarningsDescription')}
            </span>
          </div>
          {t('RecruitedFriendPage.totalEarnings')}
        </div>
        <div className={styles.earningItemValue}>
          {referralsList?.total_earnings.toFixed(2)} {user?.currency_sign}
        </div>
      </div>
      <div className={styles.earningItem}>
        <div className={styles.earningItemTitle}>
          <div className={styles.tooltip}>
            <img src={toAbsoluteUrl('/assets/icons/Payments/helpTooltip_icon.png')} alt='tooltip' />
            <span className={styles.tooltipText}>
              {t('RecruitedFriendPage.potentialEarningsDescription')}
            </span>
          </div>
          {t('RecruitedFriendPage.potentialEarnings')}
        </div>
        <div className={styles.earningItemValue}>
          {referralsList?.potential_earnings.toFixed(2)} {user?.currency_sign}
        </div>
      </div>
    </div>
  )
}

export default Earnings
