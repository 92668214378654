import {useState, useContext, useEffect} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {loadingHandler} from 'helpers'
import {toAbsoluteUrl} from 'helpers'
import axios from 'axios'
// STYLES
import styles from './AffiliatePopup.module.scss'

// COMPONENTS
import CampaignPopupMenu from './components/CampaignPopupMenu/CampaignPopupMenu'
import CampaignDetail from './components/CampaignDetail/CampaignDetail'
import Loading from 'components/Shared/Loading/Loading'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import {createCampaignService} from 'services/campaignService'
import {AffiliateCampaignDetail} from 'services/model/response/campaigns/affiliateCampaignDetail'

type StreamerList = {
  count: number
  streamers: [
    {
      link_to_logo: string | null
      title: string
    }
] | null
}

const AffiliatePopup = () => {
  const campaignService = createCampaignService()
  let {campaignId} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [isPopupTab, setIsPopupTab] = useState('affiliate')
  const [pageLoading, setPageLoading] = useState(true)
  const [campaign, setCampaign] = useState<AffiliateCampaignDetail | null>(null)
  const [streamerList, setStreamerList] = useState<StreamerList>({
    streamers: null,
    count: 0,
  })

  const routePath = location.pathname.split('/')[1]

  if (campaignId === undefined) {
    campaignId = location.pathname.split('/')[3]
  }

  // GET CAMPAIGN DATA AND POPULATE POPUP
  const fetchCampaign = async (id: number) => {
    try {
      const response = await campaignService.getAffiliateCampaignDetail(id)
      setCampaign(response)
    } catch (error) {
      console.log(error)
    }
  }
  // GET STREAMERS THAT ACCEPT CAMPAIGN
  const getAcceptedStreamers = async (id: number) => {
    if (campaignId === undefined) return
    try {
      const response = await campaignService.getAcceptedStreamers(id)
      setStreamerList(response.data)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    if (campaignId === undefined) {
      navigate(`/${routePath}`)
      return
    }
    const campaignIdNumber = parseInt(campaignId)
    if (isNaN(campaignIdNumber)) {
      navigate(`/${routePath}`)
      return
    }
    loadingHandler(
      [getAcceptedStreamers(campaignIdNumber), fetchCampaign(campaignIdNumber)],
      setPageLoading
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [campaignId])

  return (
    <div className={styles.popupWrap}>
      <div className={styles.popup}>
        {!pageLoading && campaign ? (
          <CampaignPopupMenu
            isPopupTab={isPopupTab}
            setIsPopupTab={setIsPopupTab}
            campaign={campaign}
          />
        ) : null}

        {isPopupTab === 'affiliate' && !pageLoading && campaign ? (
          <CampaignDetail
            campaign={campaign}
            setCampaign={setCampaign}
            streamerList={streamerList}
          />
        ) : (
          <Loading />
        )}

        <button
          className={styles.closePopup}
          onClick={() => {
            navigate(`/${routePath}`)
          }}
        >
          <img src={toAbsoluteUrl('/assets/icons/closeIcon.png')} alt='close' />
        </button>
      </div>
    </div>
  )
}

export default AffiliatePopup
