import styles from './StreamingCode.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {AppLevelContext} from 'context/AppLevelContextFirebase'
const StreamingCode = () => {
  const {user} = useContext(AppLevelContext)
  const navigate = useNavigate()
  const {t} = useTranslation()
  return (
    <div className={styles.streamingCode}>
      <div className={styles.streamingCode_title}>
        <img src={toAbsoluteUrl('/assets/icons/Dashboard/streamingCodeIcon.png')} alt='icon' />
        {t('DashboardPage.streamingCode')}
      </div>
      <div className={styles.streamingCode_content}>
        <p>{t('DashboardPage.oneLinkCampaigns')}</p>
        <div className={styles.streamingCode_contentButtons}>
          <button className={styles.buttonHelp} onClick={() => navigate('/help-center/setup/34')}>
            <img src={toAbsoluteUrl('/assets/icons/Dashboard/helpIcon.png')} alt='icon' />
            {t('DashboardPage.needHelp')}
          </button>
          <button
            className={styles.buttonCopy}
            onClick={() => {
              navigator.clipboard.writeText(
                `https://api.livad.stream/streamers/${user?.streamer_id}/ad_display_screen`
              )
              toast.success('Copied to clipboard')
            }}
          >
            <img src={toAbsoluteUrl('/assets/icons/Dashboard/codeIcon.png')} alt='icon' />
            {t('DashboardPage.copyYourCode')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default StreamingCode
