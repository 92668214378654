import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
// STYLES
import styles from '../../ListView.module.scss'

type ListViewHeaderProps = {
  variant?: 'featured' | ''
}

const ListViewHeader = ({variant = ''}: ListViewHeaderProps) => {
  const {t} = useTranslation()
  return (
    <tbody>
      <tr className={styles.header}>
        <td>{t('CampaignsPage.brand')}</td>
        <td>{t('CampaignsPage.campaignName')}</td>
        <td>{t('CampaignsPage.type')}</td>
        <td>{t('CampaignsPage.remainingTime')}</td>
        {variant !== 'featured' && <td>RPM</td>}
        <td></td>
      </tr>
    </tbody>
  )
}

export default ListViewHeader
