import {useContext, useEffect, useState} from 'react'
import {monthNames, extractDate, getDaysInMonth} from 'helpers'
import styles from './ProfileDetails.module.scss'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'
//CONTEXT
import SettingsContext from 'context/SettingsContext'
import {toast} from 'react-toastify'

//COMPONENTS
import CountrySelect from './components/CountrySelect'
import CitySelect from './components/CitySelect'
import {ProfileDetailsDto} from 'services/model/dto/streamerAccountModels'
import {useLocation, useNavigate} from 'react-router-dom'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'

const ProfileDetails = () => {
  const {t} = useTranslation()
  const {
    profileDetails,
    updateProfileDetails,
    checkProfileDetailsSave,
    checkProfileDetailsDiff,
    countries,
    cities,
    phoneCodes,
    getAllCities,
  } = useContext(SettingsContext)
  const [newProfileDetails, setNewProfileDetails] = useState<ProfileDetailsDto>({})
  const navigate = useNavigate()

  const location = useLocation()

  const routePath = location.pathname.split('/')[1]

  type Birthday = {
    year: string | number
    month: string
    day: string | number
  }

  const [bday, setBday] = useState<Birthday>({
    year: 'Select Year',
    month: 'Select Month',
    day: 'Select Day',
  })

  const [daysInMonth, setDaysInMonth] = useState(31)

  useEffect(() => {
    if (profileDetails) {
      setNewProfileDetails(profileDetails)
      const extractedDate = extractDate(profileDetails.birth_date)
      setBday({
        year: extractedDate?.year ?? 'Select Year',
        month: extractedDate?.monthName ?? 'Select Month',
        day: extractedDate?.day ?? 'Select Day',
      })
    }
  }, [profileDetails])

  useEffect(() => {
    if (newProfileDetails?.country_id) {
      getAllCities(newProfileDetails?.country_id)
    }
  }, [newProfileDetails.country_id])

  useEffect(() => {
    setDaysInMonth(
      getDaysInMonth(
        bday.month !== 'Select Month' ? monthNames.indexOf(bday.month) + 1 : 1,
        bday.year !== 'Select Year' ? parseInt(bday.year as string) : new Date().getFullYear()
      )
    )
  }, [bday.year, bday.month])

  useEffect(() => {
    if (bday.year !== 'Select Year' && bday.month !== 'Select Month' && bday.day !== 'Select Day') {
      const formattedBirthDate = `${bday.year}-${monthNames.indexOf(bday.month) + 1}-${bday.day}`
      setNewProfileDetails({...newProfileDetails, birth_date: formattedBirthDate})
    }
  }, [bday])

  return (
    <div className={styles.profileDetails}>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.firstName')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.firstName')}
          value={newProfileDetails.first_name}
          onChange={(e) => {
            setNewProfileDetails({...newProfileDetails, first_name: e.target.value})
          }}
        />
      </div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.lastName')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.lastName')}
          value={newProfileDetails.last_name}
          onChange={(e) => {
            setNewProfileDetails({...newProfileDetails, last_name: e.target.value})
          }}
        />
      </div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.email')}</div>
        <input
          type='email'
          placeholder={'' + t('SettingsPage.email')}
          value={newProfileDetails.contact_email}
          onChange={(e) => {
            setNewProfileDetails({...newProfileDetails, contact_email: e.target.value})
          }}
        />
      </div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.gender')}</div>
        <select
          value={newProfileDetails.gender}
          onChange={(e) => {
            setNewProfileDetails({
              ...newProfileDetails,
              gender: e.target.value,
            })
          }}
        >
          <option hidden>{t('SettingsPage.select')}</option>
          <option value='Male'>{t('SettingsPage.male')}</option>
          <option value='Female'>{t('SettingsPage.female')}</option>
          <option value='Nonbinary'>{t('SettingsPage.nonbinary')}</option>
        </select>
      </div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.phone')}</div>
        <div className={styles.inLine}>
          <select
            value={newProfileDetails.phone_code}
            onChange={(e) => {
              setNewProfileDetails({
                ...newProfileDetails,
                phone_code: e.target.value,
              })
            }}
          >
            <option hidden>{t('SettingsPage.selectPhoneCode')}</option>
            {phoneCodes.map((code, idx) => (
              <option key={idx} value={code['value']}>
                {code?.['label']}
              </option>
            ))}
          </select>
          <input
            type='text'
            placeholder={'' + t('SettingsPage.phone')}
            value={newProfileDetails.phone}
            onChange={(e) => {
              setNewProfileDetails({...newProfileDetails, phone: e.target.value})
            }}
          />
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.birthday')}</div>
        <div className={styles.inLine}>
          <select
            value={bday.year}
            onChange={(e) => {
              setBday({...bday, year: e.target.value, month: 'Select Month', day: 'Select Day'})
            }}
          >
            <option>Select Year</option>
            {Array.from({length: 80}, (_, i) => i + 1940)
              .sort((a, b) => b - a)
              .map((year, idx) => (
                <option key={idx} value={year}>
                  {year}
                </option>
              ))}
          </select>
          <select
            disabled={bday.year === 'Select Year'}
            value={bday.month}
            onChange={(e) => {
              setBday({...bday, month: e.target.value, day: 'Select Day'})
            }}
          >
            <option>Select Month</option>
            {monthNames.map((month, idx) => (
              <option key={idx} value={month}>
                {t(`ShortMonthName.${month.toLowerCase()}`)}
              </option>
            ))}
          </select>
          <select
            disabled={bday.month === 'Select Month'}
            value={bday.day}
            onChange={(e) => {
              setBday({...bday, day: parseInt(e.target.value)})
            }}
          >
            <option>Select Day</option>
            {Array.from({length: daysInMonth}, (_, i) => i + 1).map((day, idx) => (
              <option key={idx} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.country')}</div>
        <CountrySelect
          newProfileDetails={newProfileDetails}
          setNewProfileDetails={setNewProfileDetails}
        />
      </div>
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.city')}</div>
        <CitySelect
          newProfileDetails={newProfileDetails}
          setNewProfileDetails={setNewProfileDetails}
        />
      </div>
      {routePath === 'signup' ? (
        <div
          className={classNames(styles.line, styles.lineSave, {
            [styles.disabled]: !checkProfileDetailsSave(newProfileDetails),
          })}
        >
          <button
            onClick={async () => {
              if (!checkProfileDetailsSave(newProfileDetails)) return
              if (checkProfileDetailsDiff(newProfileDetails)) {
                const success = await updateProfileDetails(newProfileDetails)
                if (success) {
                  navigate(`/${routePath}/${EnumSettingsTab.SocialAccounts}`)
                }
              } else {
                navigate(`/${routePath}/${EnumSettingsTab.SocialAccounts}`)
              }
            }}
          >
            {t('Buttons.next')}
          </button>
        </div>
      ) : (
        <>
          {checkProfileDetailsDiff(newProfileDetails) &&
          checkProfileDetailsSave(newProfileDetails) ? (
            <div className={classNames(styles.line, styles.lineSave)}>
              <button
                onClick={() => {
                  updateProfileDetails(newProfileDetails)
                }}
              >
                {t('Buttons.save')}
              </button>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default ProfileDetails
