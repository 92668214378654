import {ReactElement, useContext, useEffect} from 'react'
import {Navigate, useLocation} from 'react-router-dom'

// CONTEXT
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import EnumRole from 'services/model/enum/enumRole'
interface PrivateRoutesProps {
  children: ReactElement,
  modPerms?: string[], // Add this prop
  modAllowed?: boolean, // Add this prop
}

const PrivateRoutes = ({children, modPerms, modAllowed}: PrivateRoutesProps): ReactElement | null => {
  const location = useLocation()
  const {user} = useContext(AppLevelContext);
  const query = new URLSearchParams(location.search);


  useEffect(() => {
    if (query.get('referrer')) {
      sessionStorage.setItem('referrer', query.get('referrer') as string)
    }
  }, [query.get('referrer')])

  
  if (!user) {
    return (
      <Navigate
        to={`/login${location.search}`}
        state={{return_url: location.pathname + location.search}}
        replace={true}
      />
    )
  } else if (user.role === EnumRole.MOD && !user.self) {
    if (modAllowed) {
      // If the route requires specific mod permissions, check if the user has them
      if (modPerms && !modPerms.every(perm => user.mod_perms.map(x=>x as string).includes(perm))) {
        return <Navigate to='/live-console' replace={true} />
      } else {
        return children
      }
    } else {
      // If the route is not mod-allowed, redirect to /live-console
      return <Navigate to='/live-console' replace={true} />
    }
  } else {
    // If the user is not a mod or is controlling their own account, they should be allowed to access any route
    return children
  }
}

export default PrivateRoutes

