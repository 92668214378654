import {toAbsoluteUrl} from 'helpers'
import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContextFirebase'
// STYLES
import styles from '../DropdownMenu/DropdownMenu.module.scss'
// NAV ROUTES
import {navList} from './navList'

const DropdownNav = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {user, handleLogout} = useContext(AppLevelContext)
  return (
    <>
      {navList.filter(x=>(user?.signup_completed || x.signupRequired === false)).map((item, index) => (
        <div
          className={styles.dropdownMenuItem}
          key={index}
          onClick={() => {
            if (item.path === null) {
              handleLogout()
            } else {
              navigate(item.path)
            }
          }}
        >
          <img src={toAbsoluteUrl(`/assets/icons/Header/${item.icon}.png`)} alt='icon' />
          {(item.path === null && t(`Buttons.${item.title}`)) || t(`Pages.${item.title}`)}
        </div>
      ))}
    </>
  )
}

export default DropdownNav
