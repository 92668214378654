import styles from './PollDemo.module.scss'
import {toAbsoluteUrl} from 'helpers'

type PollDemoProps = {
  question: string
  options: {
    id: number
    text: string
    is_correct: boolean
    campaign_id: number
    interactive_asset: string | null
    interactive_redirect_link: string | null
    interactive_trigger_id: number | null
  }[]
}

const PollDemo = ({question, options}: PollDemoProps) => {
  return (
    <div className={styles.pollDemo}>
      <div className={styles.demoScreen}>
        <div className={styles.content}></div>
      </div>
      <div className={styles.previewChat}>
        <div className={styles.pollSession}>
          <div className={styles.title}>{question}</div>
          <div className={styles.pollOptions}>
            {options.map((answer, index) => {
              return (
                <div key={index} className={styles.option}>
                  <div className={styles.optionText}>{answer?.text}</div>
                </div>
              )
            })}
          </div>
          <div className={styles.voteBtn}>Vote</div>
          <div className={styles.timerBar}></div>
        </div>
        <div className={styles.chatLoop}>
          <img
            className={styles.chatLoop}
            src={toAbsoluteUrl('/assets/icons/Campaigns/prod_chatloop.gif')}
            alt='chat'
          />
        </div>
      </div>
    </div>
  )
}

export default PollDemo
