// LAYOUTS
import MasterLayout from 'layouts/MasterLayout'
//ROUTES
import {useRoutes} from 'react-router-dom'
import routesPath from 'routes/routes'
import notSignedUpRoutes from 'routes/notSignedUpRoutes'
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import { useContext } from 'react'



const App = () => {
  const {
    user
  } = useContext(AppLevelContext);
  const routing = useRoutes(routesPath)
  const notSignedUpRouting = useRoutes(notSignedUpRoutes)
  
  if (user && !user.signup_completed) {
    return (
      <MasterLayout>
        {notSignedUpRouting}
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </MasterLayout>
    );
  }

  return (
    <MasterLayout>
      {routing}
      {/* <Route path="*" element={<Navigate to="/" />} /> */}
    </MasterLayout>
  );
}

export default App
