import {useState} from 'react'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'

//STYLES
import styles from './KickAuthForm.module.scss'

interface KickAuthFormProps {
  setIsTab: (tab: string) => void
}

const KickAuthForm = ({setIsTab}: KickAuthFormProps) => {
  const [isView, setIsView] = useState('signIn')
  return (
    <div className={styles.kickAuthForm}>
      {isView === 'signIn' ? (
        <div className={styles.loginView}>
          <div className={styles.authFormWrap}>
            <div className={styles.inputLine}>
              <button
                className={styles.backBtn}
                onClick={() => {
                  setIsTab('sign')
                }}
              >
                <img src={toAbsoluteUrl('/assets/icons/Auth/backIcon.png')} alt='Back' />
                Back to Platform Selection
              </button>
            </div>
            <div className={styles.inputLine}>
              <input type='text' placeholder='Email' id='email' />
              <span className={styles.inputIcon}>
                <img
                  src={toAbsoluteUrl('/assets/icons/Auth/email-icon.png')}
                  alt='Email'
                  draggable='false'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </span>
            </div>
            <div className={styles.inputLine}>
              <input
                type='password'
                placeholder='Password'
                id='password'
                // onKeyDown={(e) => {
                //   if (e.key === 'Enter') {
                //     handleLogin(e)
                //   }
                // }}
              />
              <span className={styles.inputIcon}>
                <img
                  src={toAbsoluteUrl('/assets/icons/Auth/password-icon.png')}
                  alt='Password'
                  draggable='false'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </span>
            </div>
            <div className={styles.authActionLine}>
              <span
                className={styles.btnForget}
                onClick={() => {
                  setIsView('forgotPw')
                }}
              >
                Forgot password?
              </span>
              <button
                onClick={() => {
                  console.log('Handle Login')
                }}
                className={classNames(styles.btnLogin, {
                  [styles.disabled]: false, //TODO: isLoading
                })}
              >
                {/* {!isLoading ? ( */}
                <span className={styles.btnText}>Login</span>
                {/* ) : (
                  <span className={styles.isLoading}>
                    <img src={toAbsoluteUrl('/assets/icons/loading.gif')} alt='Loading' />
                  </span>
                )} */}
              </button>
            </div>
            <div className={styles.authActionLine}>
              <span className={styles.btnSignup}>
                Don't have an account?{' '}
                <span
                  style={{color: '#5d7fff', cursor: 'pointer'}}
                  onClick={() => {
                    setIsView('signUp')
                  }}
                >
                  Sign up
                </span>
              </span>
            </div>
          </div>
        </div>
      ) : isView === 'signUp' ? (
        <div className={styles.signupView}>
          <div className={styles.authFormWrap}>
            <div className={styles.inputLine}>
              <button
                className={styles.backBtn}
                onClick={() => {
                  setIsView('signIn')
                }}
              >
                <img src={toAbsoluteUrl('/assets/icons/Auth/backIcon.png')} alt='Back' /> Back to
                Login
              </button>
            </div>

            <div className={styles.inputLine}>
              <input type='text' placeholder='Email' id='email' />
              <span className={styles.inputIcon}>
                <img
                  src={toAbsoluteUrl('/assets/icons/Auth/email-icon.png')}
                  alt='Email'
                  draggable='false'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </span>
            </div>

            <div className={styles.inputLine}>
              <input type='password' placeholder='Password' id='password' />
              <span className={styles.inputIcon}>
                <img
                  src={toAbsoluteUrl('/assets/icons/Auth/password-icon.png')}
                  alt='Password'
                  draggable='false'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </span>
            </div>

            <div className={styles.inputLine}>
              <input
                type='password'
                placeholder='Confirm Password'
                id='re-password'
                // onKeyDown={(e) => {
                //   if (e.key === 'Enter') handleSignup()
                // }}
              />
              <span className={styles.inputIcon}>
                <img
                  src={toAbsoluteUrl('/assets/icons/Auth/password-icon.png')}
                  alt='Password'
                  draggable='false'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </span>
            </div>

            <div className={styles.inputLine}>
              <button
                onClick={() => {
                  console.log('Handle Signup')
                }}
                className={classNames(styles.signUpBtn, {
                  // [styles.disabled]: isLoading, //TODO: isLoading
                })}
              >
                {/* {!isLoading ? ( */}
                <span className={styles.btnText}>Sign up</span>
                {/* ) : (
                  <span className={styles.isLoading}>
                    <img src={toAbsoluteUrl('/assets/icons/loading.gif')} alt='Loading' />
                  </span>
                )} */}
              </button>
            </div>
          </div>
        </div>
      ) : isView === 'forgotPw' ? (
        <div className={styles.forgetPasswordView}>
          <div className={styles.authFormWrap}>
            <div className={styles.inputLine}>
              <button
                className={styles.backBtn}
                onClick={() => {
                  setIsView('signIn')
                }}
              >
                <img src={toAbsoluteUrl('/assets/icons/Auth/backIcon.png')} alt='Back' /> Back to
                Login
              </button>
            </div>

            <div className={styles.inputLine}>
              <input
                // onKeyDown={(e) => {
                //   if (e.key === 'Enter') {
                //     handlePasswordReset(e)
                //   }
                // }}
                type='text'
                placeholder='Email'
                id='email'
              />
              <span className={styles.inputIcon}>
                <img
                  src='https://storage.googleapis.com/livad-ads/Images/Auth/email-icon.png'
                  alt=''
                  draggable='false'
                  onContextMenu={(e) => e.preventDefault()}
                />
              </span>
            </div>

            <div className={styles.inputLine}>
              <button
                onClick={() => {
                  console.log('Handle Password Reset')
                }}
                className={classNames(styles.resetPasswordBtn, {
                  // [styles.disabled]: isLoading, //TODO: isLoading
                })}
              >
                {/* {!isLoading ? ( */}
                <span className={styles.btnText}>Request Password Reset</span>
                {/* ) : (
                  <span className={styles.isLoading}>
                    <img src={toAbsoluteUrl('/assets/icons/loading.gif')} alt='Loading' />
                  </span>
                )} */}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default KickAuthForm
