import classNames from 'classnames'
import {useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {toAbsoluteUrl} from 'helpers'

// CONTEXT
import {AppLevelContext} from 'context/AppLevelContextFirebase'

import styles from '../../HelpCenter.module.scss'
import '../../HelpCenter.css'

const SingleQuestion = ({question, setQuestions, questions, position}) => {
  const navigate = useNavigate()
  const {user} = useContext(AppLevelContext)
  const {t} = useTranslation()
  const [correctAnswer, setCorrectAnswer] = useState(null)

  const handleToggle = () => {
    const newQuestions = [...questions]

    newQuestions.forEach((question, idx) => {
      if (idx === position) {
        return
      } else {
        question.is_opened = false
      }
    })

    newQuestions[position].is_opened = !newQuestions[position].is_opened
    if (newQuestions[position].is_opened) {
      navigate(`/help-center/${question.category}/${question.id}`)
    } else {
      navigate(`/help-center/${question.category}`)
    }
    setQuestions(newQuestions)
  }

  const answerSelection = () => {
    setCorrectAnswer(question.answer.answerKey)
  }

  useEffect(() => {
    answerSelection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions])

  return (
    <div
      className={
        'helpCenterPage ' +
        classNames(styles.articleItem, {
          [styles.opened]: question.is_opened,
        })
      }
    >
      <div className={styles.articleItem_header} onClick={handleToggle} id={question.id}>
        <h1>{t('HelpCenterPage.' + question.question.questionKey + '')}</h1>
        <img src={toAbsoluteUrl('/assets/icons/HelpCenter/plus-icon.png')} alt='detail' />
      </div>

      {/* ANSWER */}
      <div
        onClick={(e) => {
          if (e.target.className === 'textGuide_copyCode') {
            navigator.clipboard.writeText(
              `https://api.livad.stream/streamers/${user.streamer_id}/ad_display_screen`
            )
            toast.success(t('Copied your unique link to the clipboard!'))
          }
        }}
        className={styles.articleItem_body}
        dangerouslySetInnerHTML={{__html: t('HelpCenterPage.' + correctAnswer + '')}}
      ></div>
    </div>
  )
}

export default SingleQuestion
