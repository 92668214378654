import styles from './StepButtons.module.scss'
import classNames from 'classnames'
import SettingsContext from 'context/SettingsContext'
import {toAbsoluteUrl} from 'helpers'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
const StepButtons = (props: {
  activeTab: number
  setActiveTab: (activeTab: number) => void
  checkOverlayStatus: string
}) => {
  const {
    signupSteps,
    completeSignup
  } = useContext(SettingsContext);
  const navigate = useNavigate()
  const location = useLocation()
  const routePath = location.pathname.split('/')[1]
  
  useEffect(() => {
    console.log('routePath', routePath)
  }, [routePath])

  if (!signupSteps) return null;


  return (
    <div className={styles.stepButtons}>
      {props.activeTab > 1 && (
        <button
          className={classNames(styles.stepBtn, styles.backBtn)}
          onClick={() => props.setActiveTab(props.activeTab - 1)}
        >
          <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepArrow.png')} alt='back' />
          Back
        </button>
      )}
      {props.activeTab < 6 && (
        <button
          className={classNames(styles.stepBtn, styles.nextBtn)}
          onClick={() => props.setActiveTab(props.activeTab + 1)}
        >
          Next
          <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepArrow.png')} alt='next' />
        </button>
      )}
      {Object.values(signupSteps).filter(x=>!x).length === 0 && props.activeTab === 6 && routePath !== 'settings' ? (
        <button
          className={classNames(styles.stepBtn, styles.finishBtn)}
          onClick={() => {
            completeSignup()
          }}
        >
          Finish
          <img src={toAbsoluteUrl('/assets/icons/OverlaySetup/stepArrow.png')} alt='finish' />
        </button>
      ): null}
    </div>
  )
}

export default StepButtons
