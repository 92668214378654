import {useContext} from 'react'
import {toAbsoluteUrl} from 'helpers'
//STYLES
import styles from './FansGuide.module.scss'
//CONTEXT
import {AppLevelContext} from 'context/AppLevelContextFirebase'
//COMPONENTS
import GuideItem from './Components/GuideItem/GuideItem'

const FansGuide = () => {
  const {user} = useContext(AppLevelContext)
  const guideList = [
    {
      guideImage: '/assets/icons/FansGuide/1.gif',
      guideTitle: '1 - 🚀 Bahşişleri Aç/Kapa',
      guideDescription:
        'İzleyicilerinin sana bahşiş gönderebilmesi için bu seçeneği <b>Açık (Enabled)</b> olarak ayarla.',
    },
    {
      guideImage: '/assets/icons/FansGuide/2.gif',
      guideTitle: '2 - 🥷 Anonim Bahşişleri Aç/Kapa',
      guideDescription:
        'Eğer bu seçeneği <b>Açık (Enabled)</b> olarak ayarlarsan izleyicilerin sana <b>anonim</b> olarak da bahşiş gönderebilirler.',
    },
    {
      guideImage: '/assets/icons/FansGuide/3.gif',
      guideTitle: '3 - 🖼️ Kanalına Ekleyeceğin Görselleri İndir',
      guideDescription:
        'İzleyicilerinin sana daha kolay bahşiş göndermesi için kanalının ‘<b>Hakkında</b>’ kısmına bir <b>LIVAD for Fans görseli</b> ekle. <a href="https://livad.blob.core.windows.net/livad-fans/streamerPack/fans-tr-assets-comp.zip" target="_blank" style="color:#5d7fff;">Görseli indirmek için buraya tıkla</a> <br><br>İster hazır tasarımları kullan, istersen indireceğin dosyada yer alan logoları kullanarak kanalına uygun yeni bir tasarım yap. <br><br>Görseli, <b>https://fans.livad.stream/' +
        user?.title +
        '</b> bağlantısı ile eklemeyi unutma.',
    },
    {
      guideImage: '/assets/icons/FansGuide/4.gif',
      guideTitle: '4 - 📍 Bahşişlerin Konumunu Ayarla',
      guideDescription:
        'İzleyicilerinin göndereceği bahşiş bildirimleri ve görsellerin ekranın hangi kısmındagösterileceğini ayarla.',
    },
    {
      guideImage: '/assets/icons/FansGuide/5.gif',
      guideTitle: '5 - 🎨 Bahşiş Bildirim Renklerini Ayarla',
      guideDescription:
        'Yayınında görünecek bahşiş bildirimlerinin renklerini özelleştir. <br><br><b>Ana renk, yazı rengi</b> ve <b>arkaplan renklerini</b> kanalına uygun şekilde düzenle.',
    },
    {
      guideImage: '/assets/icons/FansGuide/6.gif',
      guideTitle: '6 - 💵 Minimum Bahşiş Tutarını Belirle',
      guideDescription:
        'Buradan, izleyicilerinin sana bahşiş gönderebilmesi için gerekli olan minimum bahşiş tutarını belirleyebilirsin.',
    },
    {
      guideImage: '/assets/icons/FansGuide/7.gif',
      guideTitle: '7 - 💸 Minimum Bahşiş Tutarlarını Özelleştir',
      guideDescription:
        'LIVAD for Fans ile izleyicilerine farklı bahşiş tipleri sunuyoruz. <br><br>Bir <b>Bahşiş tipine özel minimum tutar</b> belirlemek için istediğin bahşiş tipine tıkla ve o tip için bir <b>minimum tutar</b> gir.',
    },
    {
      guideImage: '/assets/icons/FansGuide/8.gif',
      guideTitle: '8 - 🔥 Takeover Bahşiş Tutarlarını Özelleştir',
      guideDescription:
        'Takeover Bahşişler çoğunlukla ekranın tamamını kaplayan kısa süreli animasyonlardır. Her bir Takeover Bahşiş için farklı bir minimum tutar ayarlayabilirsin.',
    },
    {
      guideImage: '',
      guideTitle: 'Son olarak..',
      guideDescription:
        '🛡️ Moderatörlerin senin yerine gelen bahşişleri kontrol edip kabul edebilirler. <b>Bahşiş Ayarları</b>’nın altında bulunan <b>Moderatörler</b> sekmesinden moderatör girişlerini sağlayabilirsin. <i>(moderatörler, kanalına ait hassas verileri görmezler)</i> <br><br>🤖 <b>Moobot</b> veya <b>Nightbot</b> gibi bir chatbot kullanıyorsan, gelirini maksimuma çıkarmak için 5-15 dakika aralığında <b>LIVAD for Fan linkini</b> kısa bir mesajla paylaşmanı öneriyoruz. Ayrıca yayın başlığına <b>!livad</b> komutunu da eklemen faydalı olacaktır.<br><br>İzleyicilerinin seni LIVAD for Fans üzerinden takip ettiğine emin ol, görüşmek üzere! ✌🏻',
    },
  ]
  return (
    <div className={styles.fansGuide}>
      <div className={styles.fansGuide_header}>
        <img src={toAbsoluteUrl('/assets/icons/FansGuide/livad-for_fans-logo-w.png')} alt='logo' />
        <span className={styles.pageTitle}>BAHŞİŞ AYARLARI</span>
      </div>
      <div className={styles.guideList}>
        {guideList.map((guide, index) => (
          <GuideItem
            key={index}
            guideImage={guide.guideImage}
            guideTitle={guide.guideTitle}
            guideDescription={guide.guideDescription}
          />
        ))}
      </div>
    </div>
  )
}

export default FansGuide
