import {createContext, useState, useContext, useEffect} from 'react'
import AppLevelContext from './AppLevelContext'
import axios from 'axios'
import { createCampaignService } from 'services/campaignService'

export type Campaign = {
  CPM: number
  asset: string
  campaign_brief: string
  campaign_id: number
  campaign_name: string
  campaign_status: string
  campaign_type: string
  client_name: string
  currency: string
  end_date: string
  pretext: string
  redirect_link: string
  start_date: string
  streamer_status: string
  url_to_logo: string
}

export interface CampaignContextInterface {
  campaignList: Campaign[] | []
  featuredCampaignList: Campaign[] | []
  getCampaignList: () => void
  getFeaturedCampaigns: () => void
  activeFilter: string
}

export const CampaignContext = createContext({
  getAllCampaigns: () => {},
  getFeaturedCampaigns: () => {},
  campaignList: [],
  featuredCampaignList: [],
  activeFilter: 'available',
  setActiveFilter: (filter: string) => {},
})

type CampaignContextType = {
  children: React.ReactNode
}

export const CampaignContextProvider = ({children}: CampaignContextType) => {
  const campaignService = createCampaignService()
  const [activeFilter, setActiveFilter] = useState('available')
  const [campaignList, setCampaignList] = useState([])
  const [featuredCampaignList, setFeaturedCampaignList] = useState([])

  //TODO Use url object and refactor
  const getAllCampaigns = async () => {
    let QUERY
    if (activeFilter === 'available') {
      QUERY = 'campaign_status=active,pending&streamer_status=requested'
    } else if (activeFilter === 'active') {
      QUERY = 'campaign_status=active,pending&streamer_status=accepted'
    } else if (activeFilter === 'past') {
      QUERY = 'campaign_status=past&streamer_status=requested,accepted,denied'
    } else if (activeFilter === 'denied') {
      QUERY = 'campaign_status=active,pending&streamer_status=denied'
    }
    try {
      const response = await campaignService.getCampaigns(QUERY)
      setCampaignList(response)
    } catch (error) {
      console.log(error)
    }
  }

  const getFeaturedCampaigns = async () => {
    try {
      const response = await campaignService.getFeaturedCampaigns()
      setFeaturedCampaignList(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CampaignContext.Provider
      value={{
        activeFilter,
        setActiveFilter,
        campaignList,
        featuredCampaignList,
        getAllCampaigns,
        getFeaturedCampaigns,
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}

export default CampaignContext
