import Loading from 'components/Shared/Loading/Loading'
import styles from '../LiveNotifications.module.scss'
import GetDonationsForStreamerResponse from 'services/model/response/donation/getDonationsForStreamerResponse'
import SingleDonation from './SingleDonation'
import {useTranslation} from 'react-i18next'
import { useContext, useEffect } from 'react'
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import EnumRole from 'services/model/enum/enumRole'
import EnumModPermType from 'services/model/enum/enumModPermType'

interface DonationsFeedProps {
  donations: GetDonationsForStreamerResponse[] | null
  pageLoading: boolean
}

const DonationsFeed = ({donations, pageLoading}: DonationsFeedProps) => {
  const {t} = useTranslation()
  const {user} = useContext(AppLevelContext)

  useEffect(() => {
    console.log('here: ', user, user?.role === EnumRole.MOD && !user?.self && !user.mod_perms.includes(EnumModPermType.ManageDonations))
  }, [])
  if (user?.role === EnumRole.MOD && !user?.self && !user.mod_perms.includes(EnumModPermType.ManageDonations)) {
    return (
      <div className={styles.noData}>{t('LiveConsolePage.noAccessDonations')}</div>
    )
  }
  return (
    <>
      {pageLoading || !donations ? (
        <Loading />
      ) : (
        <>
          {donations && donations.length > 0 ? (
            <>
              {donations.map((donation, index) => {
                return <SingleDonation key={index} donation={donation} />
              })}
            </>
          ) : (
            <div className={styles.noData}>{t('LiveConsolePage.noData')}</div>
          )}
        </>
      )}
    </>
  )
}

export default DonationsFeed
