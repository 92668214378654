export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

// CALCULATE REMAINING TIME
export const calculateRemainingTime = (endDate) => {
  const now = new Date()
  const end = new Date(endDate)
  // Get day difference
  const diff = end - now
  let days = Math.floor(diff / (1000 * 60 * 60 * 24))
  if (days < 0) days = 0
  let text = 'days left'
  if (days === 1) {
    text = 'day left'
  } else if (days === 0) {
    text = 'last day'
    days = ''
  }

  return {days, text}
}

// TAKE DATE AND RETURN DAYS MONTHS YEAR
export const extractDate = (date) => {
  // Pase date in format 'YYYY-MM-DD'
  const d = new Date(date)
  const year = d.getFullYear()
  const month = d.getMonth()
  const day = d.getDate()
  const monthName = monthNames[month]
  return {year, month, day, monthName}
}

export const getDaysInMonth = (month, year) => {
  // month is in the range 1..12
  return new Date(year, month, 0).getDate();
};

export const formatDate = (format, date) => {
  if (format === 'MMM DD, YYYY') {
    return `${monthNames[extractDate(date).month].slice(0, 3)} ${extractDate(date).day}, ${
      extractDate(date).year
    }`
  }
}
