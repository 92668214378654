import styles from './Problems.module.scss'
import {toAbsoluteUrl} from 'helpers'
import classNames from 'classnames'
import {useTranslation} from 'react-i18next'

const Problems = ({problems, setProblems}: any) => {
  const {t} = useTranslation()
  return (
    <div className={styles.problems_content}>
      <div className={styles.problems_title}>{t('MyClipsPopup.problems')}</div>

      {problems.map((problem: any, index: number) => (
        <div
          className={styles.problemItem}
          key={index}
          onClick={() => {
            const newLabels = problems.map((l: any) => {
              if (l.id === problem.id) {
                if (l.selected === false || l.selected === 0) {
                  l.selected = 1
                } else {
                  l.selected = 0
                }
              }
              return l
            })
            setProblems(newLabels)
          }}
        >
          <div
            className={classNames(styles.problemCheckbox, {
              [styles.checked]: problem.selected === 1,
            })}
          >
            <img src={toAbsoluteUrl('/assets/icons/MyClips/checkIcon.png')} alt='checked' />
          </div>
          {problem.name}
        </div>
      ))}
    </div>
  )
}

export default Problems
