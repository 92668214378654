import styles from '../CountrySelect/CountrySelect.module.scss'
import {useState, useContext, useEffect} from 'react'
import {toAbsoluteUrl} from 'helpers'
import ClickAwayListener from 'react-click-away-listener'

// Context
import PayoutAccountContext from 'context/PayoutAccountContext'
import {City} from 'context/PayoutAccountContext'

const CitiesSelect = () => {
  const {cities, payoutAccountData, setPayoutAccountData} = useContext(PayoutAccountContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCities, setFilteredCities] = useState<City[]>(cities)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCity, setSelectedCity] = useState<City | null>(null)

  const filterCity = (searchTerm: string) => {
    if (searchTerm === '') return setFilteredCities(cities)

    const filteredCities = cities.filter((city) => {
      return (
        city.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
        city.label.toLocaleLowerCase('tr').includes(searchTerm.toLocaleLowerCase('tr')) ||
        city.label.toLocaleLowerCase('pt').includes(searchTerm.toLocaleLowerCase('pt')) ||
        city.label.toLocaleLowerCase('es').includes(searchTerm.toLocaleLowerCase('es'))
      )
    })
    setFilteredCities(filteredCities)
  }

  useEffect(() => {
    filterCity(searchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  useEffect(() => {
    if (!payoutAccountData) return
    if (payoutAccountData.individual_city_id || payoutAccountData.business_city_id) {
      let selectedCity: any

      if (
        payoutAccountData.billing_account_type === 'personal' ||
        payoutAccountData.billing_preference === 'not_available'
      ) {
        selectedCity = cities.find((city) => city.value === payoutAccountData.individual_city_id)
      } else {
        selectedCity = cities.find((city) => city.value === payoutAccountData.business_city_id)
      }

      if (selectedCity) {
        setSelectedCity(selectedCity)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!payoutAccountData) return
    if (selectedCity) {
      if (
        payoutAccountData.billing_account_type === 'business' &&
        payoutAccountData.billing_preference === 'available'
      ) {
        setPayoutAccountData({
          ...payoutAccountData,
          business_city_id: Number(selectedCity.value),
        })
      } else {
        setPayoutAccountData({
          ...payoutAccountData,
          individual_city_id: Number(selectedCity.value),
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity])

  const handleCountryClick = (city: City) => {
    setSelectedCity(city)
    setSearchTerm('')
    setIsOpen(false)
  }

  if (!payoutAccountData) return null

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false)
        setSearchTerm('')
      }}
    >
      <div className={styles.selectBox}>
        <div className={styles.selectBox_selected} onClick={() => setIsOpen(!isOpen)}>
          {selectedCity ? selectedCity.label : 'Select City'}
          <img
            className={styles.selectBox_arrow}
            src={toAbsoluteUrl('/assets/icons/Settings/arrowIcon.png')}
            alt='arrow'
          />
        </div>
        {isOpen && (
          <div className={styles.selectItem_list}>
            <div className={styles.listItem_search}>
              <input
                type='text'
                placeholder='Search'
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className={styles.listResults}>
              {filteredCities.map((city, idx) => (
                <span
                  className={styles.listItem}
                  key={idx}
                  onClick={() => handleCountryClick(city)}
                >
                  {city.label}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default CitiesSelect
