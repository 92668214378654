import {useContext} from 'react'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import {useTranslation} from 'react-i18next'
// STYLES
import styles from '../../Settings.module.scss'
// CONTEXT
import SettingsContext from 'context/SettingsContext'
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'
import {useLocation, useNavigate} from 'react-router-dom'
import EnumPlatform from 'services/model/enum/EnumPlatform'

const SettingsMenu = () => {
  const {user} = useContext(AppLevelContext)
  const {activeTab, setActiveTab} = useContext(SettingsContext)
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()

  const routePath = location.pathname.split('/')[1]

  return (
    <div className={styles.section_menu}>
      <div
        onClick={() => navigate(`/${routePath}/${EnumSettingsTab.ProfileDetails}`)}
        className={classNames(
          styles.section_title,
          activeTab === EnumSettingsTab.ProfileDetails && styles.active
        )}
      >
        <img src={toAbsoluteUrl('/assets/icons/Settings/profile.png')} alt='' />
        {t('SettingsPage.profileDetails')}
      </div>
      {user?.signup_completed ? (
        <div
          onClick={() => navigate(`/${routePath}/${EnumSettingsTab.PayoutAccount}`)}
          className={classNames(
            styles.section_title,
            activeTab === EnumSettingsTab.PayoutAccount && styles.active
          )}
        >
          <img src={toAbsoluteUrl('/assets/icons/Settings/payout.png')} alt='' />
          {t('SettingsPage.payoutAccount')}
        </div>
      ) : null}

      <div
        onClick={() => navigate(`/${routePath}/${EnumSettingsTab.SocialAccounts}`)}
        className={classNames(
          styles.section_title,
          activeTab === EnumSettingsTab.SocialAccounts && styles.active
        )}
      >
        <img src={toAbsoluteUrl('/assets/icons/Settings/social.png')} alt='' />
        {t('SettingsPage.socialAccounts')}
      </div>
      <div
        onClick={() => navigate(`/${routePath}/${EnumSettingsTab.StreamingPreferences}`)}
        className={classNames(
          styles.section_title,
          activeTab === EnumSettingsTab.StreamingPreferences && styles.active
        )}
      >
        <img src={toAbsoluteUrl('/assets/icons/Settings/streaming.png')} alt='' />
        {t('SettingsPage.streamingPreferences')}
      </div>
      <div
        onClick={() => navigate(`/${routePath}/${EnumSettingsTab.SponsorshipPreferences}`)}
        className={classNames(
          styles.section_title,
          activeTab === EnumSettingsTab.SponsorshipPreferences && styles.active
        )}
      >
        <img src={toAbsoluteUrl('/assets/icons/Settings/campaigns.png')} alt='' />
        {t('SettingsPage.sponsorshipPreferences')}
      </div>
      {user?.signup_completed ? (
        <div
          onClick={() => navigate(`/${routePath}/${EnumSettingsTab.DonationPreferences}`)}
          className={classNames(
            styles.section_title,
            activeTab === EnumSettingsTab.DonationPreferences && styles.active
          )}
        >
          <img src={toAbsoluteUrl('/assets/icons/Settings/donation.png')} alt='' />
          {t('SettingsPage.donationPreferences')}
        </div>
      ) : null}
      {user?.signup_completed && user.platform === EnumPlatform.Twitch ? (
        <div
          onClick={() => navigate(`/${routePath}/${EnumSettingsTab.Moderators}`)}
          className={classNames(
            styles.section_title,
            activeTab === EnumSettingsTab.Moderators && styles.active
          )}
        >
          <img src={toAbsoluteUrl('/assets/icons/Settings/moderators.png')} alt='' />
          {t('SettingsPage.moderators')}
        </div>
      ) : null}
      <div
        onClick={() => navigate(`/${routePath}/${EnumSettingsTab.Interests}`)}
        className={classNames(
          styles.section_title,
          activeTab === EnumSettingsTab.Interests && styles.active
        )}
      >
        <img src={toAbsoluteUrl('/assets/icons/Settings/interests.png')} alt='' />
        {t('SettingsPage.interests')}
      </div>

      <div
        onClick={() => navigate(`/${routePath}/${EnumSettingsTab.OverlaySetup}`)}
        className={classNames(
          styles.section_title,
          activeTab === EnumSettingsTab.OverlaySetup && styles.active
        )}
      >
        <img src={toAbsoluteUrl('/assets/icons/Settings/overlaySetup.png')} alt='' />
        {t('SettingsPage.overlaySetup')}
      </div>
    </div>
  )
}

export default SettingsMenu
