import {useContext, useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {loadingHandler} from 'helpers'
// CONTEXT
import SettingsContext from 'context/SettingsContext'
import PayoutAccountContext from 'context/PayoutAccountContext'
// STYLES
import styles from './Settings.module.scss'
// COMPONENTS
import SettingsMenu from './components/SettingsMenu/SettingsMenu'
import Onboarding from './components/Onboarding/Onboarding'
import ProfileDetails from './components/ProfileDetails/ProfileDetails'
import SocialAccounts from './components/SocialAccounts/SocialAccounts'
import StreamingPreferences from './components/StreamingPreferences/StreamingPreferences'
import SponsorshipPreferences from './components/SponsorshipPreferences/SponsorshipPreferences'
import Interests from './components/Interests/Interests'
import Loading from 'components/Shared/Loading/Loading'
import DonationPreferences from './components/DonationPreferences/DonationPreferences'
import PayoutAccount from './components/PayoutAccount/PayoutAccount'
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import OverlaySetup from './components/OverlaySetup/OverlaySetup'
import Moderators from './components/Moderators/Moderators'
import {useLocation, useNavigate} from 'react-router-dom'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'

const Settings = () => {
  const {user} = useContext(AppLevelContext)
  const {
    getStreamersAccount,
    activeTab,
    setActiveTab,
    getAllInterests,
    getAllCountries,
    getStreamerLanguages,
    fetchDonationPreferences,
    fetchMods,
    fetchAutoAcceptSettings,
  } = useContext(SettingsContext)
  const {getCountries, getPayoutAccountData, getTaxDocuments} = useContext(PayoutAccountContext)
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  // GET THIS FROM API - TODO
  const [pageLoading, setPageLoading] = useState(true)

  const routePath = location.pathname.split('/')[1]
  const tmpTab = location.pathname.split('/')[2]
  useEffect(() => {
    if (tmpTab && Object.values(EnumSettingsTab).includes(tmpTab as any)) {
      setActiveTab(tmpTab as any)
    } else {
      navigate(`/settings/${EnumSettingsTab.ProfileDetails}`)
    }
  }, [tmpTab])

  // PAGE TITLE
  document.title = t('Pages.settings') + ' • LIVAD'

  useEffect(() => {
    loadingHandler(
      [
        getStreamersAccount(),
        getAllInterests(),
        getAllCountries(),
        getStreamerLanguages(),
        getCountries(),
        getPayoutAccountData(),
        // fetchDonationPreferences(),
        // fetchMods(),
        fetchAutoAcceptSettings(),
        getTaxDocuments(),
      ],
      setPageLoading
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.settings_container}>
      {!user?.signup_completed ? (
        <Onboarding />
      ) : (
        <div className={styles.header}>
          <span className={styles.title}>{t('Pages.settings')}</span>
          <span className={styles.desc}>{t('SettingsPage.manageAccountSettingsPrompt')}</span>
        </div>
      )}
      <div className={styles.content}>
        <SettingsMenu />

        {!pageLoading ||
        [EnumSettingsTab.DonationPreferences, EnumSettingsTab.Moderators].includes(activeTab) ? (
          <div className={styles.section_component}>
            {activeTab === EnumSettingsTab.ProfileDetails ? (
              <ProfileDetails />
            ) : activeTab === EnumSettingsTab.SocialAccounts ? (
              <SocialAccounts />
            ) : activeTab === EnumSettingsTab.StreamingPreferences ? (
              <StreamingPreferences />
            ) : activeTab === EnumSettingsTab.SponsorshipPreferences ? (
              <SponsorshipPreferences />
            ) : activeTab === EnumSettingsTab.DonationPreferences ? (
              <DonationPreferences />
            ) : activeTab === EnumSettingsTab.PayoutAccount ? (
              <PayoutAccount />
            ) : activeTab === EnumSettingsTab.Interests ? (
              <Interests />
            ) : activeTab === EnumSettingsTab.OverlaySetup ? (
              <OverlaySetup />
            ) : activeTab === EnumSettingsTab.Moderators ? (
              <Moderators />
            ) : null}
          </div>
        ) : (
          <Loading width='10' height='10' />
        )}
      </div>
    </div>
  )
}

export default Settings
