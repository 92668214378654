import PayoutAmountsDto from 'services/model/dto/payoutAmountsDto'
import styles from './Amount.module.scss'
import {useTranslation} from 'react-i18next'
import checkEmptyNumber from 'helpers/checkEmptyNumber'
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import { useContext } from 'react'

type AmountsProps = {
  grossAmount: number
  setGrossAmount: (grossAmount: number) => void
  disabled: boolean
}
const Amount = ({ grossAmount, setGrossAmount, disabled }: AmountsProps) => {
  const {t} = useTranslation()
  const {user} = useContext(AppLevelContext)
  return (
    <div className={styles.payoutRequest_form}>
      <div className={styles.payoutRequest_form_title}>{t('PayoutRequestPopup.amount')}</div>
      <div className={styles.payoutRequest_form_input}>
        <span className={styles.requestCurrency}>{user?.currency_sign}</span>
        <input 
          type="number"
          disabled={disabled}
          onKeyDown={(e) => {
            const { key } = e
            if (checkEmptyNumber(parseInt(key)) && key !== 'Backspace') {
              e.preventDefault()
            }
          }}
          value={grossAmount || ''}
          onChange={(e) => {
            let value = Math.round(parseFloat(e.target.value))
            value = !checkEmptyNumber(value) ? value : 0

            setGrossAmount(value)
          }}
          placeholder={'' + t('PayoutRequestPopup.amount')} 
        />
      </div>
    </div>
  )
}

export default Amount
