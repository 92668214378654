import {useContext} from 'react'
import styles from '../../CampaignDetail.module.scss'
import {toAbsoluteUrl} from 'helpers'

import {Campaign} from 'components/CampaignPopup/CampaignPopup'

//CONTEXTS
import {AppLevelContext} from 'context/AppLevelContextFirebase'

type CampaignDemoProps = {
  campaign: Campaign | null
}

const CampaignDemo = ({campaign}: CampaignDemoProps) => {
  const {user} = useContext(AppLevelContext)

  const convertVhToPercent = (vh: string) => {
    // check if the string is a valid vh value
    if (!vh.includes('vh')) return vh
    // remove the vh part of the string
    const value = vh.slice(0, -2)
    // convert to percentage
    return `${value}%`
  }

  return (
    <div className={styles.previewScreen}>
      <div className={styles.demoScreen}>
        <div className={styles.content}>
          {campaign?.asset_type === 'video' ? (
            <video
              key={campaign?.asset}
              autoPlay
              loop
              muted
              disablePictureInPicture
              playsInline
              style={{
                width: campaign?.width || 'auto',
                left: campaign?.left_padding,
                top: convertVhToPercent(campaign?.top_padding),
                // transform: `translateY(-${convertVhToPercent(campaign?.top_padding)})`,
                height: campaign?.width === 'auto' ? '100%' : 'auto',
                //TODO: height: campaign?.height || 'auto',
              }}
            >
              <source src={campaign?.asset} type='video/webm' />
              Your browser does not support the video.
            </video>
          ) : campaign?.asset_type === 'image' ? (
            <img
              src={campaign?.asset}
              alt='campaign'
              style={{
                width: campaign?.width || 'auto',
                left: campaign?.left_padding,
                top: convertVhToPercent(campaign?.top_padding),
                // transform: `translateY(-${convertVhToPercent(campaign?.top_padding)})`,
                height: campaign?.width === 'auto' ? '100%' : 'auto',
                //TODO: height: campaign?.height || 'auto',
              }}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.demoChat}>
        <div className={styles.demoUser}>
          <img
            src={toAbsoluteUrl(
              `/assets/icons/Campaigns/${user?.title ? 'streamer' : 'mod'}-icon.png`
            )}
            alt='Badge'
          />{' '}
          <span className={styles.username}>{user?.title ?? 'livadbot'}</span>: {campaign?.pretext}
          <a target='_blank' rel='noreferrer' href={campaign?.redirect_link}>
            livad.gg/r/f4thbs7
          </a>
        </div>
        <img
          className={styles.chatLoop}
          src={toAbsoluteUrl('/assets/icons/Campaigns/prod_chatloop.gif')}
          alt='chat'
        />
      </div>
    </div>
  )
}

export default CampaignDemo
