import {useState, useContext, createContext, useEffect} from 'react'
import axios from 'axios'
import {AppLevelContext} from './AppLevelContextFirebase'
import {toast} from 'react-toastify'

import {Campaign} from './CampaignContext'
import { createLiveConsoleService } from 'services/liveConsoleService'
import { AdAvailabilitiesResponse } from 'services/model/response/liveConsole/adAvailabilitiesResponse'
import useInterval from 'hooks/useInterval'
import { ManualAdRequest } from 'services/model/request/liveConsole/manualAdRequest'
import EnumRole from 'services/model/enum/enumRole'
import { createCampaignService } from 'services/campaignService'
import { createAdSettingsService } from 'services/adSettingsService'
export interface LiveConsoleContextInterface {
  getAllCampaigns: () => void
  activeFilter: string
  campaignList: Campaign[] | []
  setActiveFilter: (filter: string) => void
  adSettings: any
  setAdSettings: (adSettings: any) => void
  getAdSettings: () => void
  saveAdSettings: (adSettings: any) => void
  adAvailabilityData: AdAvailabilitiesResponse | null
  postManualAd: (manualAdRequest: ManualAdRequest) => Promise<void>
  postRandomAd: () => Promise<void>
}

export const LiveConsoleContext = createContext<LiveConsoleContextInterface>({
  getAllCampaigns: () => {},
  activeFilter: 'active',
  campaignList: [],
  setActiveFilter: (filter: string) => {},
  adSettings: null,
  setAdSettings: (adSettings: any) => {},
  getAdSettings: () => {},
  saveAdSettings: (adSettings: any) => {},
  adAvailabilityData: null,
  postManualAd: (manualAdRequest: ManualAdRequest) => Promise.resolve(),
  postRandomAd: () => Promise.resolve()
})

interface LiveConsoleContextProps {
  children: React.ReactNode
}

export const LiveConsoleProvider = ({children}: LiveConsoleContextProps) => {
  const campaignService = createCampaignService()
  const adSettingsService = createAdSettingsService()
  const {user} = useContext(AppLevelContext)
  const liveConsoleService = createLiveConsoleService();
  const [activeFilter, setActiveFilter] = useState('active')
  const [campaignList, setCampaignList] = useState([])
  const [adSettings, setAdSettings] = useState<any>(null)
  const [adAvailabilityData, setAdAvailabilityData] = useState<AdAvailabilitiesResponse | null>(null)
  const [isPostingAd, setIsPostingAd] = useState<boolean>(false)

  useEffect(() => {
    fetchAdAvailabilities();
  },[])

  useInterval(() => {
    if (isPostingAd) {
      return;
    }
    if (adSettings?.ad_posting_preference === 'automated') {
      return;
    }
    if (user?.role === EnumRole.MOD && !user?.self) {
      return;
    }
    fetchAdAvailabilities();
  }, 10000)

  useEffect(() => {
    if (adSettings?.ad_posting_preference === 'automated') {
      setAdAvailabilityData(null);
    }
  },[adSettings?.ad_posting_preference])


  //TODO Use url object and refactor
  const getAllCampaigns = async () => {
    let QUERY
    if (activeFilter === 'available') {
      QUERY = 'campaign_status=active,pending&streamer_status=requested'
    } else if (activeFilter === 'active') {
      QUERY = 'campaign_status=active,pending&streamer_status=accepted'
    } else if (activeFilter === 'past') {
      QUERY = 'campaign_status=past&streamer_status=requested,accepted,denied'
    } else if (activeFilter === 'denied') {
      QUERY = 'campaign_status=active,pending&streamer_status=denied'
    }
    try {
      const response = await campaignService.getCampaigns(QUERY)
      setCampaignList(response)
    } catch (error) {
      console.log(error)
    }
  }

  const saveAdSettings = async (adSettings: any) => {
    try {
      await adSettingsService.updateAdSettings(adSettings);
      toast.success('Ad settings saved successfully');
    } catch (error) {
      toast.error('Error saving ad settings');
      console.log(error)
    }
  }

  const getAdSettings = async () => {
    try {
      const response = await adSettingsService.getAdSettings();
      setAdSettings(response);
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAdAvailabilities = async () => {
    try {
      const response = await liveConsoleService.getAdAvailabilities();
      setAdAvailabilityData(response);
    } catch(error) {
      console.log(error);
    }
  }

  const postManualAd = async (body: ManualAdRequest): Promise<void> => {
    if (!adAvailabilityData?.streamerAvailable || !adAvailabilityData.availableCampaignIds.includes(body.campaignId)) {
      return;
    }
    setIsPostingAd(true);
    setAdAvailabilityData({
      streamerAvailable: false,
      availableCampaignIds: []
    })
    try {
      await liveConsoleService.postManualAd(body);
    } catch(error) {
      console.log(error);
    }
    setIsPostingAd(false);
  }

  const postRandomAd = async (): Promise<void> => {
    if (!adAvailabilityData?.streamerAvailable) {
      return;
    }
    setIsPostingAd(true);
    setAdAvailabilityData({
      streamerAvailable: false,
      availableCampaignIds: []
    });
    try {
      await liveConsoleService.postRandomAd();
    } catch(error) {
      console.log(error);
    }    
  }

  return (
    <LiveConsoleContext.Provider
      value={{
        getAllCampaigns,
        activeFilter,
        campaignList,
        setActiveFilter,
        saveAdSettings,
        getAdSettings,
        adSettings,
        setAdSettings,
        adAvailabilityData,
        postManualAd,
        postRandomAd
      }}
    >
      {children}
    </LiveConsoleContext.Provider>
  )
}

export default LiveConsoleContext
