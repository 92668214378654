import {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from 'helpers'
import {Navigate, useLocation} from 'react-router-dom'
import {gsap} from 'gsap'

//STYLES
import styles from './Login.module.scss'

//COMPONENTS
import KickAuthForm from './KickAuthForm/KickAuthForm'

// CONTEXT
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import EnumPlatform from 'services/model/enum/EnumPlatform'
import Loading from 'components/Shared/Loading/Loading'

const Login = () => {
  document.title = 'LIVAD for Streamers'
  const {user, userLoading, initialLoading, tokenLoading} = useContext(AppLevelContext)
  const [isTab, setIsTab] = useState('sign')
  const location = useLocation()
  const from = location.state?.return_url || '/'

  const handleLogin = (platform: EnumPlatform) => {
    let redirectLink = `${process.env.REACT_APP_AUTH_API_URL}/auth/${platform}/redirect`

    let queryArray = []
    //For local usage of authentication
    if (process.env.REACT_APP_LOCAL_SECRET) {
      queryArray.push(`localSecret=${process.env.REACT_APP_LOCAL_SECRET}`)
      // redirectLink += `?localSecret=${process.env.REACT_APP_LOCAL_SECRET}`
    }
    let referrer = sessionStorage.getItem('referrer')

    if (referrer) {
      queryArray.push(`referrer=${referrer}`)
    }
    if (queryArray.length > 0) {
      redirectLink += `?${queryArray.join('&')}`
    }
    window.location.href = redirectLink
  }

  useEffect(() => {
    const lvd = gsap.timeline({
      defaults: {duration: 0.75, ease: 'power1.out'},
    })

    lvd.fromTo(
      '.' + styles.livadAuth,
      {scale: 0},
      {scale: 1, ease: 'elastic.out(0.6, 0.4)', duration: 1.5}
    )
    lvd.fromTo('.' + styles.bannerTitle, {opacity: 0, x: -50}, {opacity: 1, x: 0}, '<')
    lvd.fromTo('.' + styles.authBanner, {opacity: 0, x: 50}, {opacity: 1, x: 0}, '<')
    lvd.fromTo('.' + styles.formContent, {opacity: 0}, {opacity: 1}, '<')

    var livadAuthBg = gsap.fromTo(
      '.' + styles.authBanner,
      1.5,
      {backgroundColor: '#869ef5'},
      {
        backgroundColor: '#5d7fff',
        ease: 'slow(0.7, 0.7, false)',
        repeat: -1,
        yoyo: true,
      }
    )
  }, [tokenLoading])

  useEffect(() => {
    console.log({user})
  }, [user])
  if (user) {
    return <Navigate to={from ?? '/dashboard'} />
  } else if (tokenLoading) {
    return null
  } else if (userLoading) {
    return <Loading />
  } else {
    return (
      <div className={styles.authBody}>
        <div className={styles.livadAuth}>
          <div className={styles.authBanner}>
            <span className={styles.bannerTitle}>
              Monetize your<br></br>livestreams with <b>LIVAD's</b>
              <br></br> <b>smart</b> layer technology
            </span>
          </div>
          <div className={styles.authForm}>
            <div className={styles.formContent}>
              <section className={styles.logoSection}>
                <img
                  id={styles.logo}
                  src={toAbsoluteUrl('assets/icons/Auth/logo.png')}
                  alt=''
                  draggable='false'
                />
                <h1 className={styles.authH1}>for Streamers</h1>
              </section>
              {isTab === 'sign' ? (
                <>
                  <div className={styles.btnWrap}>
                    <button
                      onClick={() => {
                        handleLogin(EnumPlatform.Twitch)
                      }}
                      id={styles.twitchBtn}
                    >
                      <img
                        src={toAbsoluteUrl('/assets/icons/Auth/twitch-icon.png')}
                        alt=''
                        draggable='false'
                      />{' '}
                      Sign in with Twitch
                    </button>
                    {/* <button
                      onClick={() => {
                        setIsTab('kickAuth')
                      }}
                      id={styles.kickBtn}
                    >
                      <img
                        src={toAbsoluteUrl('/assets/icons/Auth/kick-icon.png')}
                        alt=''
                        draggable='false'
                      />{' '}
                      Sign in with Kick
                    </button> */}
                    <button
                      id={styles.youtubeBtn}
                      onClick={() => {
                        handleLogin(EnumPlatform.Youtube)
                      }}
                    >
                      <img
                        src={toAbsoluteUrl('/assets/icons/Auth/yu-icon.png')}
                        alt=''
                        draggable='false'
                      />{' '}
                      Sign in with YouTube
                    </button>
                    <button
                      id={styles.trovoBtn}
                      onClick={() => {
                        handleLogin(EnumPlatform.Trovo)
                      }}
                    >
                      <img
                        src={toAbsoluteUrl('/assets/icons/Auth/trovo-icon.png')}
                        alt=''
                        draggable='false'
                      />{' '}
                      Sign in with Trovo
                    </button>
                  </div>
                  <section className={styles.footerSection}>
                    <p className={styles.authAgree}>
                      By proceeding, you confirm that you have read and <br />
                      agree to our{' '}
                      <a href='https://www.livad.stream/terms-and-conditions' target='_blank'>
                        Terms of Use
                      </a>{' '}
                      and{' '}
                      <a href='https://www.livad.stream/privacy-policy' target='_blank'>
                        Privacy Policy
                      </a>
                    </p>
                    <span className={styles.needHelp}>
                      Need help? Join{' '}
                      <a href='https://discord.gg/livad' target='_blank' rel='noreferrer'>
                        LIVAD Discord
                      </a>{' '}
                      to get help
                    </span>
                  </section>
                </>
              ) : isTab === 'kickAuth' ? (
                <>
                  <KickAuthForm setIsTab={setIsTab} />
                  <section className={styles.footerSection}>
                    <p className={styles.authAgree}>
                      By proceeding, you confirm that you have read and <br />
                      agree to our{' '}
                      <a href='https://www.livad.stream/terms-and-conditions' target='_blank'>
                        Terms of Use
                      </a>{' '}
                      and{' '}
                      <a href='https://www.livad.stream/privacy-policy' target='_blank'>
                        Privacy Policy
                      </a>
                    </p>
                    <span className={styles.needHelp}>
                      Need help? Join{' '}
                      <a href='https://discord.gg/livad' target='_blank' rel='noreferrer'>
                        LIVAD Discord
                      </a>{' '}
                      to get help
                    </span>
                  </section>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
