import styles from '../../PayoutAccount.module.scss'
import {useContext, useEffect, useState} from 'react'
import PayoutAccountContext from 'context/PayoutAccountContext'
import CitiesSelect from '../CitiesSelect/CitiesSelect'
import DistrictSelect from '../DistrictSelect/DistrictSelect'
import {toAbsoluteUrl} from 'helpers'
import {createPayoutService} from 'services/payoutService'
import EnumStreamerTaxDocumentType from 'services/model/enum/enumStreamerTaxDocumentType'
import {useTranslation} from 'react-i18next'

const TaxExemptInputs = () => {
  const {t} = useTranslation()
  const payoutService = createPayoutService()
  const {payoutAccountData, setPayoutAccountData, taxDocuments, uploadTaxDocument} =
    useContext(PayoutAccountContext)
  const [exemptionProgress, setExemptionProgress] = useState<number | null>(null)
  const [petitionProgress, setPetitionProgress] = useState<number | null>(null)

  useEffect(() => {
    console.log({taxDocuments})
  }, [taxDocuments])

  useEffect(() => {
    console.log({exemptionProgress})
  }, [exemptionProgress])

  useEffect(() => {
    console.log({petitionProgress})
  }, [petitionProgress])
  if (!payoutAccountData) return null

  return (
    <>
      {/* Full name */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.fullName')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.fullName')}
          value={payoutAccountData.individual_full_name}
          onChange={(e) =>
            setPayoutAccountData({
              ...payoutAccountData,
              individual_full_name: e.target.value.replace(/[^a-zA-Z\s]/g, ''),
            })
          }
        />
      </div>

      {/* City */}
      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.city')}</div>
        <CitiesSelect />
      </div>

      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.district')}</div>
        <DistrictSelect />
      </div>

      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.nationalId')}</div>
        <input
          type='text'
          placeholder={'' + t('SettingsPage.nationalId')}
          defaultValue={payoutAccountData?.individual_id_number}
          onChange={(e) => {
            setPayoutAccountData({
              ...payoutAccountData,
              individual_id_number: e.target.value,
            })
          }}
        />
      </div>

      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.petitionDocument')}</div>
        <div className={styles.desc}>{t('SettingsPage.bankNotificationLetter')}</div>
        {petitionProgress === null ? (
          <label className={styles.fileUpload} htmlFor='fileOne'>
            <input
              type='file'
              id='fileOne'
              name='file'
              onChange={(e) => {
                console.log(e)
                if (e.target.files?.length) {
                  uploadTaxDocument(
                    EnumStreamerTaxDocumentType.TRPetition,
                    e.target.files[0],
                    setPetitionProgress
                  )
                }
              }}
            />
            <img src={toAbsoluteUrl('/assets/icons/Settings/uploadFile.png')} alt='upload' />
            {t('SettingsPage.uploadPdfFile')}
          </label>
        ) : null}
        {taxDocuments?.petitionDocument || petitionProgress ? (
          <div className={styles.uploadingStatus}>
            <img src={toAbsoluteUrl('/assets/icons/Settings/uploadedFile.png')} alt='icon' />
            <div className={styles.uploadContent}>
              <div className={styles.fileContent}>
                {taxDocuments?.petitionDocument ? (
                  <div className={styles.fileName}>{taxDocuments?.petitionDocument?.fileName}</div>
                ) : null}
                {/* TODO: If file is uploaded, replace text like this : 'Uploaded' */}
                {petitionProgress ? (
                  <div className={styles.uploaded}>{petitionProgress}%</div>
                ) : null}
              </div>
              {/* TODO: If file is uploaded, hide this progress bar */}
              {petitionProgress ? (
                <div className={styles.progressBar}>
                  <div
                    className={styles.progress}
                    style={{
                      width: `${petitionProgress}%`,
                    }}
                  ></div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>

      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.taxExemptionDocument')}</div>
        <div className={styles.desc}>{t('SettingsPage.relatedTaxExemptionDocument')}</div>
        {exemptionProgress === null ? (
          <label className={styles.fileUpload} htmlFor='fileTwo'>
            <input
              type='file'
              id='fileTwo'
              name='file'
              onChange={(e) => {
                console.log(e)
                if (e.target.files?.length) {
                  uploadTaxDocument(
                    EnumStreamerTaxDocumentType.TRExemption,
                    e.target.files[0],
                    setExemptionProgress
                  )
                }
              }}
            />
            <img src={toAbsoluteUrl('/assets/icons/Settings/uploadFile.png')} alt='upload' />
            {t('SettingsPage.uploadPdfFile')}
          </label>
        ) : null}
        {taxDocuments?.exemptionDocument || exemptionProgress ? (
          <div className={styles.uploadingStatus}>
            <img src={toAbsoluteUrl('/assets/icons/Settings/uploadedFile.png')} alt='icon' />
            <div className={styles.uploadContent}>
              <div className={styles.fileContent}>
                {taxDocuments?.exemptionDocument ? (
                  <div className={styles.fileName}>{taxDocuments?.exemptionDocument?.fileName}</div>
                ) : null}
                {/* TODO: If file is uploaded, replace text like this : 'Uploaded' */}
                {exemptionProgress ? (
                  <div className={styles.uploaded}>{exemptionProgress}%</div>
                ) : null}
              </div>
              {/* TODO: If file is uploaded, hide this progress bar */}
              {exemptionProgress ? (
                <div className={styles.progressBar}>
                  <div
                    className={styles.progress}
                    style={{
                      width: `${exemptionProgress}%`,
                    }}
                  ></div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>

      <div className={styles.line}>
        <div className={styles.label}>{t('SettingsPage.payoutMethod')}</div>
        <select disabled>
          <option value='wire' selected>
            Wire
          </option>
        </select>
      </div>

      <div className={styles.line}>
        <div className={styles.label}>IBAN</div>
        <input
          type='text'
          placeholder='IBAN'
          defaultValue={payoutAccountData.IBAN}
          onChange={(e) => {
            setPayoutAccountData({
              ...payoutAccountData,
              IBAN: e.target.value,
            })
          }}
        />
      </div>
    </>
  )
}

export default TaxExemptInputs
