import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
import {useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from 'helpers'
//COMPONENTS
import NotificationItem from './components/NotificationItem/NotificationItem'
import NotificationPopup from './components/NotificationPopup/NotificationPopup'
//STYLES
import styles from './Notifications.module.scss'
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import EnumStreamerNotificationType from 'services/model/enum/enumStreamerNotificationType'
import Loading from 'components/Shared/Loading/Loading'
import NotificationDto from 'services/model/dto/notifications/notificationDto'

const Notifications = () => {
  const {notifications, unreadNotifications, readNotifications, getNotifications} =
    useContext(AppLevelContext)
  const {t} = useTranslation()
  document.title = t('Pages.notifications') + ' • LIVAD'
  const [notificationSection, setNotificationSection] =
    useState<EnumStreamerNotificationType | null>(null)
  const [notificationPopup, setNotificationPopup] = useState<NotificationDto | null>(null)
  useEffect(() => {
    getNotifications()
  }, [])
  return (
    <>
      <div className={styles.notifications}>
        <div className={styles.containerHeader}>
          <span className={styles.pageTitle}>{t('NotificationsPage.notificationsTitle')}</span>
          <span className={styles.pageDesc}>{t('NotificationsPage.notificationsDesc')}</span>
        </div>
        <div className={styles.containerBody}>
          <div className={styles.bodyHeader}>
            <span className={styles.bodyHeaderTitle}>Notifications</span>
            <button
              onClick={() => {
                if (notifications?.length) {
                  readNotifications(notifications.map((item) => item.id))
                }
              }}
              className={styles.markRead}
            >
              Mark all as read
            </button>
          </div>
          <div className={styles.notificationSelection}>
            <div
              className={classNames(styles.notificationSelectionItem, {
                [styles.active]: notificationSection === null,
              })}
              onClick={() => setNotificationSection(null)}
            >
              All
            </div>
            <div
              className={classNames(styles.notificationSelectionItem, {
                [styles.active]: notificationSection === EnumStreamerNotificationType.Sponsorship,
              })}
              onClick={() => setNotificationSection(EnumStreamerNotificationType.Sponsorship)}
            >
              Sponsorships
            </div>
            <div
              className={classNames(styles.notificationSelectionItem, {
                [styles.active]: notificationSection === EnumStreamerNotificationType.News,
              })}
              onClick={() => setNotificationSection(EnumStreamerNotificationType.News)}
            >
              News
            </div>
            <div
              className={classNames(styles.notificationSelectionItem, {
                [styles.active]: notificationSection === EnumStreamerNotificationType.Announcement,
              })}
              onClick={() => setNotificationSection(EnumStreamerNotificationType.Announcement)}
            >
              Announcements
            </div>
            <div
              className={classNames(styles.notificationSelectionItem, {
                [styles.active]: notificationSection === EnumStreamerNotificationType.Warning,
              })}
              onClick={() => setNotificationSection(EnumStreamerNotificationType.Warning)}
            >
              Warnings
            </div>
            <div
              className={classNames(styles.notificationSelectionItem, {
                [styles.active]: notificationSection === EnumStreamerNotificationType.Other,
              })}
              onClick={() => setNotificationSection(EnumStreamerNotificationType.Other)}
            >
              Other
            </div>
          </div>
          {notifications ? (
            <div className={styles.notificationList}>
              {notifications
                .filter((x) => {
                  if (notificationSection === null) {
                    return true
                  }
                  return x.type === notificationSection
                })
                .map((notification, index) => (
                  <NotificationItem
                    key={index}
                    item={notification}
                    setNotificationPopup={setNotificationPopup}
                  />
                ))}
            </div>
          ) : !notifications ? (
            <div className={styles.noData}>
              <img src={toAbsoluteUrl('/assets/icons/Notifications/bellIcon.png')} alt='icon' />
              <span>No notifications yet</span>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
      {notificationPopup ? (
        <NotificationPopup
          notification={notificationPopup}
          setNotificationPopup={setNotificationPopup}
        />
      ) : null}
    </>
  )
}

export default Notifications
