import styles from '../CountrySelect/CountrySelect.module.scss'
import {useState, useContext, useEffect} from 'react'
import {toAbsoluteUrl} from 'helpers'
import ClickAwayListener from 'react-click-away-listener'

// Context
import PayoutAccountContext from 'context/PayoutAccountContext'
import {District} from 'context/PayoutAccountContext'

const DistrictSelect = () => {
  const {districts, payoutAccountData, setPayoutAccountData} = useContext(PayoutAccountContext)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedDistrict, setSelectedDistrict] = useState<District | null>(null)

  useEffect(() => {
    if (!payoutAccountData) return
    if (payoutAccountData.individual_district_id || payoutAccountData.business_district_id) {
      let selectedDistrict: any
      if (
        payoutAccountData.billing_account_type === 'personal' ||
        payoutAccountData.billing_preference === 'not_available'
      ) {
        selectedDistrict = districts.find(
          (district) => district.id === payoutAccountData.individual_district_id
        )
      } else {
        selectedDistrict = districts.find(
          (district) => district.id === payoutAccountData.business_district_id
        )
      }
      if (selectedDistrict) {
        setSelectedDistrict(selectedDistrict)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!payoutAccountData) return
    if (
      selectedDistrict &&
      (payoutAccountData.billing_account_type === 'personal' ||
        payoutAccountData.billing_preference === 'not_available')
    ) {
      setPayoutAccountData({
        ...payoutAccountData,
        individual_district_id: selectedDistrict.id,
      })
    }

    if (
      selectedDistrict &&
      payoutAccountData.billing_account_type === 'business' &&
      payoutAccountData.billing_preference === 'available'
    ) {
      setPayoutAccountData({
        ...payoutAccountData,
        business_district_id: selectedDistrict.id,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistrict])

  const handleCountryClick = (district: District) => {
    setSelectedDistrict(district)
    setIsOpen(false)
  }

  if (!payoutAccountData) return null

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false)
      }}
    >
      <div className={styles.selectBox}>
        <div className={styles.selectBox_selected} onClick={() => setIsOpen(!isOpen)}>
          {selectedDistrict ? selectedDistrict.name : 'Select District'}
          <img
            className={styles.selectBox_arrow}
            src={toAbsoluteUrl('/assets/icons/Settings/arrowIcon.png')}
            alt='arrow'
          />
        </div>
        {isOpen && (
          <div className={styles.selectItem_list}>
            <div className={styles.listResults}>
              {districts.map((district, idx) => (
                <span
                  className={styles.listItem}
                  key={idx}
                  onClick={() => handleCountryClick(district)}
                >
                  {district.name}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default DistrictSelect
