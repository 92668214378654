import { createBaseStreamerApiService } from "./baseStreamerApiService"



export const createClipService = () => {
    const baseService = createBaseStreamerApiService('/clips');

    const updateLabels = async (adId: number, labels: number[]) => {
        const response = await baseService.put(`/${adId}/labels`, { labels });
        return response.data;
    }

    const updateProblems = async (adId: number, problems: number[]) => {
        const response = await baseService.put(`/${adId}/problems`, { problems });
        return response.data;
    }

    const getClip = async (adId: number): Promise<any> => {
        const response = await baseService.get<any>(`/${adId}`);
        return response.data;
    }

    const getClips = async (query: string): Promise<{
        data: any
    }> => {
        const response = await baseService.get<{
            data: any
        }>(`/${query}`);
        return response.data;
    }

    return {
        updateLabels,
        updateProblems,
        getClip,
        getClips
    }
}