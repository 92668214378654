import {useContext, useEffect, useState} from 'react'
// TOASTIFY
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from './MasterLayout.module.scss'
// COMPONENTS
import Sidebar from '../components/Sidebar/Sidebar'
import Header from '../components/Header/Header'
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import classNames from 'classnames'
import {useLocation} from 'react-router-dom'

interface MasterLayoutProps {
  children: React.ReactNode
}

const MasterLayout = ({children}: MasterLayoutProps) => {
  const {user, userLoading, tokenLoading} = useContext(AppLevelContext)
  const [sidebarToggle, setSidebarToggle] = useState(false)
  const location = useLocation()
  const [showNav, setShowNav] = useState(true)

  useEffect(() => {
    if (location.pathname.includes('login')) {
      setShowNav(false)
    } else {
      setShowNav(true)
    }
  }, [location.pathname])
  return (
    <div className={styles.masterLayout}>
      <ToastContainer pauseOnHover={false} theme={'dark'} />
      {user?.signup_completed ? (
        <>
          {showNav && <Sidebar sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />}
        </>
      ) : null}
      <div className={classNames(styles.content, [{[styles.noSideBar]: !user?.signup_completed}])}>
        {/* HEADER */}
        {showNav && !tokenLoading && (
          <Header sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
        )}
        {/* CONTENT */}
        <div className={styles.contentContainer}>{children}</div>
      </div>
    </div>
  )
}

export default MasterLayout
