import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
//STYLES
import styles from './NotificationItem.module.scss'
import NotificationDto from 'services/model/dto/notifications/notificationDto'
import EnumStreamerNotificationType from 'services/model/enum/enumStreamerNotificationType'
import formatNotificationTime from 'helpers/formatNotificationTime'
import { useContext } from 'react'
import {AppLevelContext} from 'context/AppLevelContextFirebase'
import { useNavigate } from 'react-router-dom'
interface NotificationItemProps {
  key: number
  item: NotificationDto
  setNotificationPopup: (value: NotificationDto | null) => void
}
const NotificationItem = ({
  key,
  item,
  setNotificationPopup
}: NotificationItemProps) => {
  const {
    notifications,
    unreadNotifications,
    readNotifications,
    getNotifications
  } = useContext(AppLevelContext);
  const navigate = useNavigate()

  const handleNotificationClick = () => {
    if (!item.isRead) {
      readNotifications([item.id])
    }
    if (item.type === EnumStreamerNotificationType.Sponsorship && item.campaign?.id) {
      navigate(`/notifications/campaign-popup/${item.campaign.id}`)
    } else if (item.detail) {
      setNotificationPopup(item)
    }
  }
  return (
    <div key={key} onClick={handleNotificationClick} className={classNames(styles.notificationItem, {
      [styles.unread]: !item.isRead 
    })}>
      <div className={classNames(styles.notificationIcon, {
        [styles.typeAnnounce]: item.type === EnumStreamerNotificationType.Announcement,
        [styles.typeNew]: item.type === EnumStreamerNotificationType.News,
        [styles.typeWarning]: item.type === EnumStreamerNotificationType.Warning,
      })}>
        <img src={
          item.type === EnumStreamerNotificationType.Sponsorship ? (item.campaign?.clientLogo) :
          item.type === EnumStreamerNotificationType.Announcement ? toAbsoluteUrl('/assets/icons/notifications/announce.png') :
          item.type === EnumStreamerNotificationType.Warning ? toAbsoluteUrl('/assets/icons/notifications/warning.png') : 
          item.type === EnumStreamerNotificationType.News ? toAbsoluteUrl('/assets/icons/notifications/new.png') :         
          toAbsoluteUrl('/assets/icons/notifications/new.png')
        } alt='icon' />
      </div>
      <div className={styles.notificationContent}>
        <div className={styles.notificationTitle}>{
          item.type === EnumStreamerNotificationType.Sponsorship ? 'New Sponsorship':
          item.title
        }</div>
        <div className={styles.notificationDesc}>
          {
            item.type === EnumStreamerNotificationType.Sponsorship ? (item.campaign?.name ?? '') :
            item.description
          }
        </div>
      </div>
      <div className={styles.notificationAction}>
        <span className={styles.notificationDate}>{
          formatNotificationTime(item.createdAt)
        }</span>
        <button className={styles.actionBtn}>
          <img src={toAbsoluteUrl('/assets/icons/notifications/detailIcon.png')} alt='detail' />
        </button>
      </div>
    </div>
  )
}

export default NotificationItem
