import {createContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
import axios from 'axios'
import i18next from 'i18next'

// Services
import {createAdminService} from 'services/adminService'
import {GetManagedStreamersResponse} from 'services/model/response/admin/getManagedStreamerResponse'
import { toast } from 'react-toastify'
import EnumPlatform from 'services/model/enum/EnumPlatform'
import EnumRole from 'services/model/enum/enumRole'
import EnumModPermType from 'services/model/enum/enumModPermType'
import EnumLanguagePreference from 'services/model/enum/enumLanguagePreference'
import { ManagedStreamer } from "services/model/dto/managedStreamer";
import NotificationDto from 'services/model/dto/notifications/notificationDto'
import { createStreamersService } from 'services/streamersService'



type User = {
  streamer_id: string,
  platform: EnumPlatform
  link_to_logo: string,
  title: string,
  created_date: Date,
  is_admin: boolean,
  is_mod: boolean
  role: EnumRole
  mod_perms: Array<EnumModPermType>
  self: boolean
  language_preference: EnumLanguagePreference
  signup_completed: boolean,
  currency_id: string
  currency_sign: string
  broadcasting_language_id?: string
  first_name?: string
  last_name?: string
}

export interface AppLevelContextInterface {
  user: User | null
  auth0Token?: string
  permissions?: string[]
  handleLogin: () => Promise<void>
  handleLogout: () => Promise<void>
  userSearchList: GetManagedStreamersResponse | null
  searchStreamers: (search: string) => void
  updateStreamer: (streamerId: string) => void
  setUserSearchList: (value: GetManagedStreamersResponse | null) => void
  mainStreamer: ManagedStreamer | null
  notifications: NotificationDto[] | null,
  unreadNotifications: number | null
  getNotifications: () => void
  readNotifications: (notificationIds: number[]) => Promise<void>
  fetchUser: () => Promise<void>
}

export const AppLevelContext = createContext<AppLevelContextInterface>({
  user: null,
  handleLogin: async () => {},
  handleLogout: async () => {},
  userSearchList: null,
  searchStreamers: () => {},
  updateStreamer: () => {},
  setUserSearchList: () => {},
  mainStreamer: null,
  notifications: null,
  unreadNotifications: null,
  getNotifications: () => {},
  readNotifications: async () => {},
  fetchUser: async () => {},
})

type AppLevelContextType = {
  children: React.ReactNode
}

export const AppLevelContextProvider = ({children}: AppLevelContextType) => {
  const adminService = createAdminService()
  const streamersService = createStreamersService()
  const location = useLocation()
  const {getAccessTokenSilently, loginWithRedirect, logout} = useAuth0()
  const [user, setUser] = useState<User | null>(null)
  const [auth0Token, setAuth0Token] = useState<string>()
  const [permissions, setPermissions] = useState<string[]>([])
  const [userSearchList, setUserSearchList] = useState<GetManagedStreamersResponse | null>(null)
  const [mainStreamer, setMainStreamer] = useState<ManagedStreamer | null>(null)
  const [notifications, setNotifications] = useState<NotificationDto[] | null>(null)
  const [unreadNotifications, setUnreadNotifications] = useState<number | null>(null)
  

  const handleLogin = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        },
      });
      const decoded = await JSON.parse(atob(token.split('.')[1]))
      setPermissions(decoded.permissions)
      sessionStorage.setItem('auth0Token', token)
      
      setAuth0Token(token)
      handleStreamerLogin(token)
    } catch (error) {
 

      const path = location.pathname;
      const dict = {
        referrer: sessionStorage.getItem('referrer') || '',
        appState: {
          returnTo: path,
        },
      }
      console.log(dict)
      loginWithRedirect(dict)
    }
  }

  const fetchUser = async () => {
    await handleStreamerLogin(sessionStorage.getItem('auth0Token') || '')
  }
  const handleStreamerLogin = async (token: string) => {
    await axios
      .post(
        `${process.env.REACT_APP_STREAMER_API_URL}/streamers`,
        {
          referrer: sessionStorage.getItem('referrer') || '',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        i18next.changeLanguage(res.data.language_preference)
        setUser(res.data)
      })
      .catch(error => {
        console.log('here', error);
        if (error?.response?.status === 404 && error?.response?.data?.message) {
          toast.error(error?.response?.data?.message)
        }
        setTimeout(() => {
          handleLogout()
        }, 5000)
      })
  }

  const handleLogout = async () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  const searchStreamers = async (search: string) => {
    const response = await adminService.searchStreamers(search)
    setUserSearchList(response)
    if (response.self) {
      setMainStreamer(response.self);
    }
  }

  const updateStreamer = async (streamerId: string) => {
    try {
      const response = await adminService.updateAdminStreamer(streamerId)
      console.log('success', response)
      window.location.reload()
    } catch(error) {
      console.log(error)
    }
  }

  const getNotifications = async () => {
    try {
      const response = await streamersService.getNotifications()
      setNotifications(response)
    } catch(error) {
      console.log(error)
    }
  }

  const readNotifications = async (ids: Array<number>) => {
    // Get ids from indexes
    try {
      if (!notifications) {
        return
      }
      await streamersService.readNotifications(ids)
      // Select the notifications that were read and mark them as isRead:true
      const newNotifications = notifications.map((notification, index) => {
        if (ids.includes(notification.id)) {
          return {
            ...notification,
            isRead: true
          }
        }
        return notification
      })
      setNotifications(newNotifications)
    } catch(error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (auth0Token) {
      sessionStorage.setItem('authToken', auth0Token)
    } else {
      sessionStorage.removeItem('authToken')
    }
  }, [auth0Token])

  useEffect(() => {
    if (sessionStorage.getItem('authToken')) {
      getNotifications();
    }
  }, [sessionStorage.getItem('authToken')])

  useEffect(() => {
    if (notifications) {
      // Get the count of notifications items that has isRead: false
      const unreadNotifications = notifications.filter((notification) => !notification.isRead);
      setUnreadNotifications(unreadNotifications.length);
    } else {
      setUnreadNotifications(null);
    }
  }, [notifications])

  return (
    <AppLevelContext.Provider
      value={{
        user,
        auth0Token,
        permissions,
        handleLogin,
        handleLogout,
        userSearchList,
        searchStreamers,
        updateStreamer,
        setUserSearchList,
        mainStreamer,
        notifications,
        unreadNotifications,
        getNotifications,
        readNotifications,
        fetchUser
      }}
    >
      {children}
    </AppLevelContext.Provider>
  )
}

export default AppLevelContext
