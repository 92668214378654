import styles from '../../Select/Select.module.scss'
import {useState, useContext, useEffect} from 'react'
import {toAbsoluteUrl} from 'helpers'
import ClickAwayListener from 'react-click-away-listener'

// Context
import SettingsContext from 'context/SettingsContext'

const CitySelect = ({newProfileDetails, setNewProfileDetails}: any) => {
  const {cities, profileDetails} = useContext(SettingsContext)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCities, setFilteredCities] = useState<any>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCity, setSelectedCity] = useState<any>(null)

  const filterCity = (searchTerm: string) => {
    if (searchTerm === '') return setFilteredCities(cities)

    const filteredCities = cities.filter((city) => {
      return city.label.toLowerCase().includes(searchTerm.toLowerCase())
    })
    setFilteredCities(filteredCities)
  }

  useEffect(() => {
    filterCity(searchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, cities])

  useEffect(() => {
    if (profileDetails?.city_id) {
      const selectedCity = cities.find((city) => city.value === profileDetails?.city_id)
      if (selectedCity) {
        setSelectedCity(selectedCity)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails?.city_id, cities])

  useEffect(() => {
    if (selectedCity) {
      setNewProfileDetails({...newProfileDetails, city_id: selectedCity.value})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity])

  // Reset selected city when country changes
  useEffect(() => {
    setSelectedCity(null)
    setSearchTerm('')
    setNewProfileDetails({...newProfileDetails, city_id: null})
  }, [newProfileDetails?.country_id])

  const handleCountryClick = (city: any) => {
    setSelectedCity(city)
    setSearchTerm('')
    setIsOpen(false)
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false)
        setSearchTerm('')
      }}
    >
      <div className={styles.selectBox}>
        <div className={styles.selectBox_selected} onClick={() => setIsOpen(!isOpen)}>
          {selectedCity ? selectedCity.label : 'Select City'}
          <img
            className={styles.selectBox_arrow}
            src={toAbsoluteUrl('/assets/icons/Settings/arrowIcon.png')}
            alt='arrow'
          />
        </div>
        {isOpen && (
          <div className={styles.selectItem_list}>
            <div className={styles.listItem_search}>
              <input
                type='text'
                placeholder='Search'
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className={styles.listResults}>
              {filteredCities?.map((city: any, idx: any) => (
                <span
                  className={styles.listItem}
                  key={idx}
                  onClick={() => handleCountryClick(city)}
                >
                  {city.label}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default CitySelect
