import styles from './ReferralsList.module.scss'
import {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {SingleReferral} from 'context/ReferralsContext'

// Context
import {AppLevelContext} from 'context/AppLevelContextFirebase'

interface SingleReferalProps {
  referral: SingleReferral
}

const SingleReferal = ({referral}: SingleReferalProps) => {
  const {user} = useContext(AppLevelContext)
  const {t} = useTranslation()
  return (
    <tr>
      <td>
        <span className={styles.streamer}>
          <img src={referral.link_to_logo} alt='streamer' />
          {referral.platform === 'twitch'
            ? referral.twitch_username
            : referral.youtube_channel_title}
        </span>
      </td>
      <td>
        {referral.milestone_completed}/{referral.milestone_count}
      </td>
      <td>
        {t('RecruitedFriendPage.viewsAmount', {
          AMOUNT: referral.milestone_earning.toFixed(2) + user?.currency_sign,
          X: referral.milestone_views.toLocaleString(),
        })}
        {/* {referral.milestone_earning.toFixed(2)} {user?.currency_sign} /{' '}
        {referral.milestone_views.toLocaleString()} Views */}
      </td>
      <td>
        <div className={styles.progress}>
          <div className={styles.progress_bar} style={{width: `${referral.milestone_progress}%`}}>
            {referral.milestone_progress}%
          </div>
        </div>
      </td>
    </tr>
  )
}

export default SingleReferal
