import Login from 'pages/FirebaseLogin/Login'
import Settings from 'pages/Settings/Settings'

// ROUTES
import PrivateRoutes from './PrivateRoutes'
// CONTEXT
import {SettingsContextProvider} from 'context/SettingsContext'
import {PayoutAccountContextProvider} from 'context/PayoutAccountContext'
import {Navigate} from 'react-router-dom'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'
import { ReactElement } from 'react'


interface Route {
  path: string
  element: ReactElement
  auth: boolean
}


export const routesPaths: Route[] = [
  {
    path: '/signup/:settingsTab',
    element: (
      <SettingsContextProvider>
        <PayoutAccountContextProvider>
          <Settings />
        </PayoutAccountContextProvider>
      </SettingsContextProvider>
    ),
    auth: true,
  },
  {
    path: '/signup',
    element: <Navigate to={`/signup/${EnumSettingsTab.ProfileDetails}`} />,
    auth: true,
  },
  {
    path: '/login',
    element: <Login />,
    auth: false,
  },
  {
    path: '/',
    element: <Navigate to={`/signup/${EnumSettingsTab.ProfileDetails}`} />,
    auth: true,
  },
  {
    path: '*',
    element: <Navigate to='/' replace={true} />,
    auth: true
  },
]


const authCheck = (routes: Route[]) =>
  routes.map((route) => {
    if (route?.auth) {
      route.element = <PrivateRoutes>{route.element}</PrivateRoutes>
    }
    return route
})

export default authCheck(routesPaths)
