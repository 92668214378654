import styles from './RecruitFriend.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {useNavigate} from 'react-router-dom'
import {useContext} from 'react'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'

//CONTEXT
import {AppLevelContext} from 'context/AppLevelContextFirebase'

const RecruitFriend = () => {
  const navigate = useNavigate()
  const {user} = useContext(AppLevelContext)
  const {t} = useTranslation()

  return (
    <div className={styles.recruitFriend}>
      <div className={styles.recruitFriend_title}>
        <img src={toAbsoluteUrl('/assets/icons/Dashboard/recruitFriendIcon.png')} alt='icon' />
        {t('DashboardPage.recruitFriend')}
      </div>
      <div className={styles.recruitFriend_content}>
        {/* <div className={styles.friends}>
          <img
            src='https://static-cdn.jtvnw.net/jtv_user_pictures/8f56178e-94ea-4278-9ab9-cca5d541bc47-profile_image-300x300.jpeg'
            alt='streamer'
            className={styles.friends_item}
          />
          <img
            src='https://static-cdn.jtvnw.net/jtv_user_pictures/d85221f8-5ada-4d0b-9280-d1b630de8c32-profile_image-300x300.png'
            alt='streamer'
            className={styles.friends_item}
          />
          <img
            src='https://pbs.twimg.com/profile_images/1375270718749966343/HUmxjbbX_400x400.jpg'
            alt='streamer'
            className={styles.friends_item}
          />
          <img
            src='https://static-cdn.jtvnw.net/jtv_user_pictures/0294fec8-8572-4517-9b60-12e0705a8893-profile_image-300x300.png'
            alt='streamer'
            className={styles.friends_item}
          />
        </div>
        <div className={styles.moreFriendsCount}>
          <span>+8</span>
          <span>...</span>
        </div> */}
        <button
          className={styles.recruitFriend_copyLinkBtn}
          onClick={() => {
            navigate('/referrals')
          }}
        >
          <img src={toAbsoluteUrl('/assets/icons/Dashboard/referralsIcon.png')} alt='referrals' />
          {t('DashboardPage.viewReferrals')}
        </button>
        <button
          className={styles.recruitFriend_copyLinkBtn}
          onClick={() => {
            navigator.clipboard.writeText(
              `https://streamer.livad.stream?referrer=${user?.streamer_id}`
            )
            toast.success('Referral link copied to clipboard')
          }}
        >
          <img src={toAbsoluteUrl('/assets/icons/Dashboard/copyCodeIcon.png')} alt='copy' />
          {t('DashboardPage.copyYourLink')}
        </button>
      </div>
    </div>
  )
}

export default RecruitFriend
