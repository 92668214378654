import {useState, createContext, useEffect, useRef, useContext, useCallback} from 'react'
import io, { Socket } from 'socket.io-client';

import GetDonationsForStreamerResponse from 'services/model/response/donation/getDonationsForStreamerResponse'
import {createDonationService} from 'services/donationService'

import UpdateDonationConsentAsStreamerRequest from 'services/model/request/donation/updateDonationConsentRequest'
import useInterval from 'hooks/useInterval'
import AppLevelContext from './AppLevelContext';
import _ from 'lodash'; // import lodash for the debounce function
import { createGamesService } from 'services/gamesService';
import GameSessionDto from 'services/model/dto/gameSessionDto';
import LiveFeedObjectDto from 'services/model/dto/liveFeedObjectDto';
import { EnumLiveFeedObjectType } from 'services/model/enum/enumLiveFeedObjectType';
import { createLiveConsoleService } from 'services/liveConsoleService';
import { LiveConsoleAdDto } from 'services/model/dto/liveConsoleAdDto';
import EnumRole from 'services/model/enum/enumRole';

export interface LiveNotificationContextInterface {
  donations: GetDonationsForStreamerResponse[] | null
  gameSessions: GameSessionDto[] | null
  ads: LiveConsoleAdDto[] | null
  liveFeed: LiveFeedObjectDto[] | null
  selectedFeed: EnumLiveFeedObjectType | 'all'
  setSelectedFeed: (feed: EnumLiveFeedObjectType | 'all') => void
  getAllDonations: () => void
  getNextDonations: () => void
  getAllGames: () => void
  getAllAds: () => void
  updateDonationConsent: (req: UpdateDonationConsentAsStreamerRequest) => Promise<void>
}

export const LiveNotificationContext = createContext<LiveNotificationContextInterface>({
  donations: null,
  gameSessions: null,
  ads: null,
  liveFeed: null,
  selectedFeed: 'all',
  setSelectedFeed: () => {},
  getAllDonations: () => {},
  getNextDonations: () => {},
  getAllGames: () => {},
  getAllAds: () => {},
  updateDonationConsent: async (req: UpdateDonationConsentAsStreamerRequest) => {},
})

interface LiveNotificationContextType {
  children: React.ReactNode
}
export const LiveNotificationProvider = ({children}: LiveNotificationContextType) => {
  const {user} = useContext(AppLevelContext)
  const donationService = createDonationService()
  const gamesService = createGamesService();
  const liveConsoleService = createLiveConsoleService();
  const [donations, setDonations] = useState<GetDonationsForStreamerResponse[] | null>(null);
  const [gameSessions, setGameSessions] = useState<GameSessionDto[] | null>(null);
  const [ads, setAds] = useState<LiveConsoleAdDto[] | null>(null);
  const [liveFeed, setLiveFeed] = useState<LiveFeedObjectDto[] | null>(null);
  const [selectedFeed, setSelectedFeed] = useState<EnumLiveFeedObjectType | 'all'>('all')
  const [fanSocket, setFanSocket] = useState<Socket | null>(null);
  const [adSocket, setAdSocket] = useState<Socket | null>(null);


  const updateDonationConsent = async (req: UpdateDonationConsentAsStreamerRequest) => {
    try {
      const data = await donationService.updateDonationConsent(req)
      await debounceUpdateDonations([data]);
    } catch (error) {
      console.log(error)
    }
  }

  const getAllDonations = async () => {
    try {
      const tempDonations = await donationService.getDonations({})
      // Return a new promise that resolves after debounceUpdateDonations is called
      await debounceUpdateDonations(tempDonations);
      return;
    } catch (error) {
      console.log(error)
    }
  }

  const getAllGames = async () => {
    try {
      const tempGameSessions = await gamesService.getGameSessions()
      // Return a new promise that resolves after debounceUpdateDonations is called
      await debounceUpdateGamesSessions(tempGameSessions);
      return;
    } catch (error) {
      console.log(error)
    }
  }

  const getAllAds = async () => {
    try {
      const tempAds = await liveConsoleService.getAds()
      // Return a new promise that resolves after debounceUpdateDonations is called
      await debounceUpdateAds(tempAds);
      return;
    } catch (error) {
      console.log(error)
    }
  }

  const getNextDonations = async () => {
    // Find the donation with the oldest created date
    if (donations === null) {
      return;
    }
    const oldestDonation = donations.reduce((oldest, donation) => {
      if (new Date(donation.createdAt).getTime() < new Date(oldest.createdAt).getTime()) {
        return donation
      }
      return oldest
    }, donations[0])
    const tempDonations = await donationService.getDonations({
      createdBefore: oldestDonation.createdAt,
    })
    await debounceUpdateDonations(tempDonations)
  }

  const debounceUpdateDonations = useCallback(
    (data: GetDonationsForStreamerResponse[]) => {
      return new Promise<void>((resolve) => {
        _.debounce(() => {
          setDonations((prevState) => {
            // If prevState is null, initialize it as an empty array
            const currentHistory = prevState === null ? [] : prevState;

            // Insert or update the new donation events, depending on whether they already exist
            // The end result should be sorted by createdAt desc
            const updatedHistory = [...currentHistory];

            data.forEach((newData) => {
              const index = updatedHistory.findIndex((d) => d.id === newData.id);
              if (index === -1) {
                updatedHistory.push(newData);
              } else {
                updatedHistory[index] = newData;
              }
            });

            updatedHistory.sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            });

            return updatedHistory;
          });
          // Resolve the promise after updating the donations state
          resolve();
        }, 300)();
      });
    },
    [] // Empty dependency array ensures that the debounce function is only created once
  );

  const debounceUpdateGamesSessions = useCallback(
    (data: GameSessionDto[]) => {
      return new Promise<void>((resolve) => {
        _.debounce(() => {
          setGameSessions((prevState) => {
            // If prevState is null, initialize it as an empty array
            const currentHistory = prevState === null ? [] : prevState;

            // Insert or update the new donation events, depending on whether they already exist
            // The end result should be sorted by createdAt desc
            const updatedSessions = [...currentHistory];

            data.forEach((newData) => {
              const index = updatedSessions.findIndex((d) => d.uuid === newData.uuid);
              if (index === -1) {
                updatedSessions.push(newData);
              } else {
                updatedSessions[index] = newData;
              }
            });

            updatedSessions.sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            });

            return updatedSessions;
          });
          // Resolve the promise after updating the donations state
          resolve();
        }, 300)();
      });
    },
    [] // Empty dependency array ensures that the debounce function is only created once
  );


  const debounceUpdateAds = useCallback(
    (data: LiveConsoleAdDto[]) => {
      return new Promise<void>((resolve) => {
        _.debounce(() => {
          setAds((prevState) => {
            // If prevState is null, initialize it as an empty array
            const currentHistory = prevState === null ? [] : prevState;

            // Insert or update the new donation events, depending on whether they already exist
            // The end result should be sorted by createdAt desc
            const updatedAds = [...currentHistory];

            data.forEach((newData) => {
              const index = updatedAds.findIndex((d) => d.adId === newData.adId);
              if (index === -1) {
                updatedAds.push(newData);
              } else {
                updatedAds[index] = newData;
              }
            });

            updatedAds.sort((a, b) => {
              return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
            });

            return updatedAds;
          });
          // Resolve the promise after updating the donations state
          resolve();
        }, 300)();
      });
    },
    [] // Empty dependency array ensures that the debounce function is only created once
  );


  const debounceUpdateLiveFeed = useCallback(
    _.debounce(() => {
      const donationFeed: LiveFeedObjectDto[] = donations
        ? donations.map((d) => ({ donation: d, type: EnumLiveFeedObjectType.Donation }))
        : [];
  
      const gameSessionFeed: LiveFeedObjectDto[] = gameSessions
        ? gameSessions.map((g) => ({ gameSession: g, type: EnumLiveFeedObjectType.GameSession }))
        : [];

      const adFeed: LiveFeedObjectDto[] = ads
        ? ads.map((a) => ({ ad: a, type: EnumLiveFeedObjectType.Ad}))
        : [];
  
      const combinedFeed = [...donationFeed, ...gameSessionFeed, ...adFeed].sort((a, b) => {
        const aCreatedAt = (
          a.type === EnumLiveFeedObjectType.Donation ? a.donation.createdAt 
          : a.type === EnumLiveFeedObjectType.GameSession ? a.gameSession.createdAt
          : a.ad.timestamp
        );
        const bCreatedAt = (
          b.type === EnumLiveFeedObjectType.Donation ? b.donation.createdAt 
          : b.type === EnumLiveFeedObjectType.GameSession ? b.gameSession.createdAt
          : b.ad.timestamp
        );
        
        return new Date(bCreatedAt).getTime() - new Date(aCreatedAt).getTime();
      });
  
      setLiveFeed(combinedFeed);
    }, 300),
    [donations, gameSessions, ads]
  );
  

  

  useEffect(() => {
    const tmpFanSocket = io(process.env.REACT_APP_FANS_API_URL || '');
    setFanSocket(tmpFanSocket);
    if (user?.streamer_id) {
      tmpFanSocket.on(`donation-streamer-${user.streamer_id}`, (data: GetDonationsForStreamerResponse) => {
        // Handle the incoming donation event data here
        console.log('Donation event received:', data);
        // Find the donation for the data
        const donationIndex = donations?.findIndex((d) => d.id === data.id);
        // If the donation exists

        debounceUpdateDonations([data]);
        
      });
    }
    if (user?.role === EnumRole.MOD && !user?.self) {
      return () => {
        tmpFanSocket.disconnect();
      }
    } else {
      const tmpAdScoket = io(process.env.REACT_APP_ADS_API_URL || '');
      setAdSocket(tmpAdScoket);
      if (user?.streamer_id) {
        tmpAdScoket.on(`ad-streamer-${user.streamer_id}`, (data: LiveConsoleAdDto) => {
          // Handle the incoming donation event data here
          console.log('Ad event received:', data);
          debounceUpdateAds([data]);
          
        });
        tmpAdScoket.on(`gameSession-streamer-${user.streamer_id}`, (data: GameSessionDto) => {
          // Handle the incoming donation event data here
          console.log('Ad event received:', data);
          debounceUpdateGamesSessions([data]);
          
        });
      }
      return () => {
        tmpFanSocket.disconnect();
        tmpAdScoket.disconnect();
  
      };
    }
    
  }, []);

  useEffect(() => {
    if (donations && gameSessions && ads) {
      debounceUpdateLiveFeed();
    }
  }, [donations, gameSessions, ads, debounceUpdateLiveFeed]);

  useEffect(() => {
    if (user?.role === EnumRole.MOD && !user.self) {
      setSelectedFeed(EnumLiveFeedObjectType.Donation)
    }
  }, [user?.role, user?.self])

  

  return (
    <LiveNotificationContext.Provider
      value={{
        donations,
        gameSessions,
        ads,
        liveFeed,
        selectedFeed,
        setSelectedFeed,
        getAllDonations,
        getNextDonations,
        getAllGames,
        getAllAds,
        updateDonationConsent,
      }}
    >
      {children}
    </LiveNotificationContext.Provider>
  )
}

export default LiveNotificationContext
