import { createBaseStreamerApiService } from "./baseStreamerApiService";



export const createIndexService = () => {
    const baseService = createBaseStreamerApiService('');
    const getCountries = async (): Promise<any> => {
        const response = await baseService.get<any>('/countries');
        return response.data;
    }

    const getCities = async (countryId: string): Promise<any> => {
        const response = await baseService.get<any>(`/countries/${countryId}/cities`);
        return response.data;
    }

    const getDistricts = async (cityId: number): Promise<any> => {
        const response = await baseService.get<any>(`/cities/${cityId}/districts`);
        return response.data;
    }

    const getReferrals = async (): Promise<any> => {
        const response = await baseService.get<any>('/referrees');
        return response.data;
    }

    const getLanguages = async (): Promise<any> => {
        const response = await baseService.get<any>('/languages');
        return response.data;
    }

    return {
        getCountries,
        getCities,
        getDistricts,
        getReferrals,
        getLanguages
    }
}