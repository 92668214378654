import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
// TRANSLATION
import './i18n'
// Context
import {AppLevelContextProvider} from 'context/AppLevelContextFirebase'
import {Auth0Provider} from '@auth0/auth0-react'
// Router
import {BrowserRouter as Router} from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
    <Router>
      {/* <Auth0Provider
        domain='login.livad.stream'
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
        authorizationParams={{
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          approval_prompt: 'force',
          redirect_uri: window.location.origin,
          access_type: 'offline',
        }}
      > */}
        <AppLevelContextProvider>
          <App />
        </AppLevelContextProvider>
      {/* </Auth0Provider> */}
    </Router>
  // </React.StrictMode>
)
