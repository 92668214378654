import {AppLevelContext} from 'context/AppLevelContextFirebase'
import React, { useContext } from 'react'

type RestrictedRouteProps = {
  children: React.ReactNode
  permission: string
  isPage?: boolean
}
const RestrictedRoute = ({
    children,
    permission,
    isPage
}: RestrictedRouteProps) => {
  const { permissions } = useContext(AppLevelContext)
  return (
    <>
      {permissions?.includes(permission)
        ? children
        : isPage
        ? "You don't have permission to view this page"
        : null}
    </>
  )
}

export default RestrictedRoute
