import {createContext, useState, useContext} from 'react'
import AppLevelContext from './AppLevelContext'
import axios from 'axios'
import { createIndexService } from 'services/indexService'

export interface SingleReferral {
  currency: string
  link_to_logo: string
  max_views: number
  milestone_completed: number
  milestone_count: number
  milestone_earning: number
  milestone_progress: number
  milestone_views: number
  platform: string
  potential_earnings: number
  streamer_id: string
  total_earnings: number
  twitch_username: string
  views: number
  youtube_channel_title: string
}

export interface ReferralList {
  potential_earnings: number
  referral_available: boolean
  referrees: SingleReferral[]
  total_earnings: number
}

export interface ReferralsContextInterface {
  referralsList: ReferralList | null
  getAllReferrals: () => void
}

export const ReferralsContext = createContext<ReferralsContextInterface>({
  referralsList: null,
  getAllReferrals: () => {},
})

type ReferralsContextType = {
  children: React.ReactNode
}

export const ReferralsContextProvider = ({children}: ReferralsContextType) => {
  const indexService = createIndexService()
  const [referralsList, setReferralsList] = useState<ReferralList | null>(null)

  const getAllReferrals = async () => {
    try {
      const response = await indexService.getReferrals()
      setReferralsList(response)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ReferralsContext.Provider
      value={{
        referralsList,
        getAllReferrals,
      }}
    >
      {children}
    </ReferralsContext.Provider>
  )
}

export default ReferralsContext
