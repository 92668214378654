import {createContext, useState, useContext} from 'react'
import AppLevelContext from './AppLevelContext'
import axios from 'axios'
import { createLeaderboardService, LeaderboardItem } from 'services/leaderboardService'


export interface LeaderboardContextInterface {
  leaderboardData: LeaderboardItem[] | null
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  getLeaderboardData: () => void
  pageLoading: boolean
}

export const LeaderboardContext = createContext<LeaderboardContextInterface>({
  leaderboardData: null,
  selectedIndex: 0,
  setSelectedIndex: (index: number) => {},
  getLeaderboardData: () => {},
  pageLoading: true,
})

type LeaderboardContextType = {
  children: React.ReactNode
}

export const LeaderboardContextProvider = ({children}: LeaderboardContextType) => {
  const leaderboardService = createLeaderboardService();
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardItem[] | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [pageLoading, setPageLoading] = useState(true)

  const getLeaderboardData = async () => {
    try {
      const response = await leaderboardService.getLeaderboardData();
      setLeaderboardData(response);
      setPageLoading(false)
    } catch(error) {
      console.log(error)
    }
    // await axios
    //   .get(`${process.env.REACT_APP_STREAMER_API_URL}/leaderboard`, config)
    //   .then((res) => {
    //     setLeaderboardData(res.data);
    //     setPageLoading(false)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }

  return (
    <LeaderboardContext.Provider
      value={{
        leaderboardData,
        selectedIndex,
        setSelectedIndex,
        getLeaderboardData,
        pageLoading,
      }}
    >
      {children}
    </LeaderboardContext.Provider>
  )
}

export default LeaderboardContext
