import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl, calculateRemainingTime} from 'helpers'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
// STYLES
import styles from '../../ListView.module.scss'
// TYPES
import {Campaign} from 'context/CampaignContext'

// CONTEXT
import {AppLevelContext} from 'context/AppLevelContextFirebase'

type SingleListItemProps = {
  campaign: Campaign
  variant?: 'featured' | ''
}

const SingleListItem = ({variant = '', campaign}: SingleListItemProps) => {
  const {t} = useTranslation()
  const {user} = useContext(AppLevelContext)
  const navigate = useNavigate()
  const {days, text} = calculateRemainingTime(campaign.end_date)

  return (
    <tbody>
      <tr className={styles.list_item}>
        <td className={styles.client_name}>
          <img src={campaign.url_to_logo} alt='' />
          {campaign.client_name}
        </td>
        <td>{campaign.campaign_name}</td>
        <td className={styles.campaign_type}>
          <img
            src={toAbsoluteUrl(
              `./assets/icons/Campaigns/${
                campaign.campaign_type === 'interactive'
                  ? 'interactive-icon.png'
                  : campaign.campaign_type === 'poll'
                  ? 'poll-icon.png'
                  : 'video-icon.png'
              }`
            )}
            alt=''
          />
          {campaign.campaign_type.slice(0, 1).toUpperCase() + campaign.campaign_type.slice(1)}
        </td>
        <td>
          <span
            className={classNames(styles.remaining_time, {
              [styles.past_campaign]: campaign.campaign_status === 'past',
            })}
          >
            <img
              src={
                campaign.campaign_status !== 'past'
                  ? toAbsoluteUrl('/assets/icons/Campaigns/remaining-time-icon.png')
                  : toAbsoluteUrl('/assets/icons/Campaigns/finished-campaign-icon.png')
              }
              alt=''
            />
            {campaign.campaign_status === 'past'
              ? t('CampaignsPage.past')
              : days === 0
              ? t('CampaignsPage.lastDay')
              : t('CampaignsPage.xDaysLeft', {X: days})}
          </span>
        </td>
        {variant !== 'featured' && (
          <td>
            {campaign.CPM} {user?.currency_sign}
          </td>
        )}
        <td
          className={styles.campaign_inspect}
          onClick={() => navigate(`/campaigns/campaign-popup/${campaign.campaign_id}`)}
        >
          <button className={styles.inspect_button}>
            <img src={toAbsoluteUrl('/assets/icons/Campaigns/inspect-icon.png')} alt='' />
            {t('CampaignsPage.inspect')}
          </button>
        </td>
      </tr>
    </tbody>
  )
}

export default SingleListItem
